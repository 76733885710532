import { STYLE_FEATURE_PROPERTIES } from '../enum'

export const DOMAIN_TYPES = {
  CUSTOM_DOMAIN: 'CUSTOM_DOMAIN',
  PREMIUM_DOMAIN: 'PREMIUM_DOMAIN',
}

// day plan for test only
export const PLAN_PRICE_DESCRIPTION = {
  day: 'per day billed daily',
  month: 'billing.plan.month',
  year: 'billing.plan.year',
}

// day plan for test only
export const PLAN_PRICE_INTERVALS = {
  day: 'day',
  month: 'month',
  year: 'year',
}

export const VAT_LAYER_KEY = '20767af96c30b1944cb1c02812845a7a'

export const STYLE_KEYS_FOR_THEME = [
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP,
  STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE,
  STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR,
  STYLE_FEATURE_PROPERTIES.FOOTER_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.FOOTER_FONT_ID,
  STYLE_FEATURE_PROPERTIES.FOOTER_COLOR,
  STYLE_FEATURE_PROPERTIES.FOOTER_SIZE,
  STYLE_FEATURE_PROPERTIES.FOOTER_OVER_SLIDESHOW,
  STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_ID,
  STYLE_FEATURE_PROPERTIES.CONTACTS_COLOR,
  STYLE_FEATURE_PROPERTIES.CONTACTS_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_SIZE,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_ID,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_COLOR,
  STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_SIZE,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR,
  STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_COLOR,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_NAME,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_ID,
  STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_SIZE,
  STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR,
  STYLE_FEATURE_PROPERTIES.HOVER_COLOR,
  STYLE_FEATURE_PROPERTIES.BURGER_COLOR,
  STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_COLOR,
  STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_VARIANT,
  STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM,
]
