import styled from 'styled-components'
import { Modal } from 'packages/components/modal'
import { media } from 'packages/components/media'

export const CenteredDropModal = styled(Modal)`
  margin: auto !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 540px !important;
  padding: 16px !important;
  gap: 14px;
`

export const StyledTextareaContainer = styled.div`
  margin-top: 20px;

  textarea {
    min-height: 120px !important;
  }
`

export const AlignContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.max.phone`
        justify-content: flex-end;
    `}
`

export const StyledDropdown = styled.div`
  ${(props) => (props.hide ? 'display: none;' : '')}
  ${(props) => (props.hide ? 'display: none;' : '')}
    margin-top: 20px;

  .ui.dropdown {
    width: 100% !important;
  }
`

export const UploadPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  gap: 24px;

  .ui.button {
    ${(props) => (props.hideButton ? 'display: none !important;' : '')}
    font-size: 13px;
    height: 40px;
    white-space: nowrap;
  }
`

export const UploadPictureInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const PreviewBlock = styled.div`
  ${(props) => (props.hide ? 'display: none;' : 'display: flex;')}
  ${(props) => (props.hide ? 'display: none;' : 'display: flex;')}
    margin-top: 20px;
  justify-content: space-between;
  gap: 24px;

  > div {
    &:last-child {
      margin-left: 20px;
      font-size: 13px;
      line-height: 140%;
      display: flex;
      align-items: center;
      height: max-content;
      gap: 24px;

      > span {
        color: #666a73;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        &:hover {
          color: #ff002e;
          svg path {
            fill: #ff002e;
          }
        }
      }

      ${media.max.phone`
                height: inherit;
                flex-direction: column;
                align-items: flex-start;
            `}
    }
  }
`

export const PreviewContainer = styled.div`
  width: 100%;
  max-width: 150px;
  max-height: 200px;
`

export const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    max-height: 100%;
    max-width: 100%;
    ${(props) => props.isLoading && `filter: blur(2px)`};
  }
`

export const UploadError = styled.div`
  width: 100%;
  background-color: #f7e4e7;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
  font-size: 13px;
`

export const UploadWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
  position: relative;
  overflow-y: auto;
  height: 100%;

  .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: #666a73;
    color: #666a73;
    font-size: 13px;
    line-height: 140%;
    padding: 8px;

    background: #f6f6f6;
    border: 1px dashed #e1e2e6;
    background: #f6f6f6;
    border: 1px dashed #e1e2e6;
    border-radius: 4px;
    position: relative;

    &:focus {
      outline-color: rgba(0, 0, 0, 0) !important;
    }
  }

  .dropzone-header {
    color: #343840;
    font-size: 24px;
    font-weight: bold;
    line-height: 150%;
    margin: 18px 0 0;
    text-align: center;

    > span {
      color: #666a73;
      color: #666a73;
      font-size: 13px;
      line-height: 140%;
      display: block;
      font-weight: normal;
    }
  }

  .dropzone-footer {
    text-align: center;
    position: absolute;
    bottom: 15px;
  }
`

export const UploadDropZone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ddf0e0;
  border: 1px dashed #58c367;
  border-radius: 4px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #242426;
`

export const UploadDropZoneContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`

export const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e2e6;
`

export const StPagePosition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const StPagePositionGroup = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
`

export const SeoSettingsWrapper = styled.div`
  .div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`
export const SeoSettingsFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  textarea {
    resize: none !important;
  }
`
export const SeoSettingsUpload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StTextarea = styled.div`
  textarea {
    height: 120px !important;
  }
`

export const StyledSlugPreview = styled.div`
  margin-top: 10px;
  color: #c1c1c1;
  font-size: 14px;
`

export const StyledSlug = styled.span`
  color: #343840;
`
