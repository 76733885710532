import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Container, Label } from './nodes'
import { FormColorPicker } from 'packages/components/inputs'

const ColorPickerComponent = ({ intl: { formatMessage }, ...props }) => {
  return (
    <Container>
      <Label>{formatMessage({ id: 'Color' })}</Label>
      <FormColorPicker {...props} />
    </Container>
  )
}

ColorPickerComponent.propTypes = {
  intl: intlShape.isRequired,
}

export const StylesColorPicker = injectIntl(ColorPickerComponent)
