import React, { useCallback, useMemo } from 'react'
import {
  FormBlock,
  SubHeader,
  StylesColorPicker,
  StylesFontSizeSlider,
  StylesFontDropdowns,
  StylesRadioGroup,
  StylesToggle,
  CustomLoaderPicker,
  StylesLayoutAlignDropdown,
  SocialLinks,
} from '@configurator/components/pages/pageStyles/components'
import {
  STYLE_FEATURE_PROPERTIES,
  FOOTER_STYLE_ITEM_TYPE,
  PORTFOLIO_TEMPLATES,
  FEATURE_PROPERTY_KEYS,
} from 'packages/enum'
import { useSelector } from 'react-redux'
import { templateLogoMarginsSelector } from 'packages/selectors/Website'
import { MarginSlider } from 'packages/components/inputs/marginSlider/marginSlider'

const getFontId = (font) => {
  const variants = (font.variants || []).join(',')
  const scripts = (font.scripts || []).join(',')
  return `${font.family}:${variants}:${scripts}`
}

const FormFontField = ({
  fontFieldName,
  fontIdFieldName,
  selectedStyle,
  values,
  initialValues,
  setFieldValue,
}) => {
  const pickerId = `${selectedStyle.type}_${fontFieldName}`
  const onChange = useCallback(
    (nextFont) => {
      if (nextFont.family !== initialValues[fontFieldName]) {
        setFieldValue(fontFieldName, nextFont.family)
        setFieldValue(fontIdFieldName, getFontId(nextFont))
      }
    },
    [fontFieldName, fontIdFieldName, initialValues, setFieldValue]
  )
  const fontFamilyProps = useMemo(
    () => ({
      name: fontFieldName,
      type: 'text',
      placeholder: 'fonts.update.placeholder.primary',
      activeFontFamily: values[fontFieldName],
      pickerId,
      onChange,
    }),
    [fontFieldName, onChange, pickerId, values]
  )
  return <StylesFontDropdowns fontFamilyProps={fontFamilyProps} />
}

const DefaultFieldsBlock = ({
  fontFieldName,
  fontIdFieldName,
  sizeFieldName,
  colorFieldName,
  selectedStyle,
  values,
  initialValues,
  setFieldValue,
}) => (
  <FormBlock>
    <FormFontField
      fontFieldName={fontFieldName}
      fontIdFieldName={fontIdFieldName}
      selectedStyle={selectedStyle}
      values={values}
      initialValues={initialValues}
      setFieldValue={setFieldValue}
    />
    <StylesFontSizeSlider name={sizeFieldName} />
    <StylesColorPicker name={colorFieldName} />
  </FormBlock>
)

export const HeaderFieldsBlock = ({ formatMessage, ...props }) => {
  const logoMargins = useSelector(templateLogoMarginsSelector)

  return (
    <>
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR}
        {...props}
      />
      <div>
        <MarginSlider
          name={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP}
          label={formatMessage({ id: 'styles.logo.marginTop' })}
          min={1}
          max={3}
          step={0.2}
          style={{ marginBottom: '20px' }}
          initialMargin={logoMargins.top}
          {...props}
        />
        <MarginSlider
          name={STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM}
          label={formatMessage({ id: 'styles.logo.marginBottom' })}
          min={1}
          max={3}
          step={0.2}
          initialMargin={logoMargins.bottom}
          {...props}
        />
      </div>
      <SubHeader>{formatMessage({ id: 'styles.title.header.sublogo' })}</SubHeader>
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR}
        {...props}
      />
    </>
  )
}

export const NavigationFieldsBlock = ({
  formatMessage,
  scrollableHeader,
  toggleScollableHeader,
  templateName,
  ...props
}) => {
  return (
    <>
      <SubHeader>{formatMessage({ id: 'styles.title.navigation.pageTitle' })}</SubHeader>
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR}
        {...props}
      />
      <SubHeader>
        {formatMessage({
          id: 'styles.title.mainNavigation.navigation',
        })}
      </SubHeader>
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_COLOR}
        {...props}
      />
      {!!scrollableHeader && templateName !== PORTFOLIO_TEMPLATES.MADRID && (
        <StylesToggle
          label='styles.header.scrollable'
          field={{
            value: scrollableHeader.enabled,
            name: FEATURE_PROPERTY_KEYS.SCROLLABLE_HEADER,
          }}
          {...props}
          onClick={toggleScollableHeader}
        />
      )}
    </>
  )
}

const footerStyleItems = [
  {
    value: FOOTER_STYLE_ITEM_TYPE.allCaps,
    label: 'styles.footer.defaultFooterLinks.allCaps',
  },
  {
    value: FOOTER_STYLE_ITEM_TYPE.firstCaps,
    label: 'styles.footer.defaultFooterLinks.firstCaps',
  },
  {
    value: FOOTER_STYLE_ITEM_TYPE.lowerCase,
    label: 'styles.footer.defaultFooterLinks.lowerCase',
  },
]

export const FooterFieldsBlock = ({
  footerOverSlideshow,
  isFooterOverSlideshowEnabled,
  slideshowEnabled,
  handleToggleFooterOverSlideshow,
  templateName,
  ...props
}) => {
  const showFooterOverSlideshowToggle =
    !!footerOverSlideshow && slideshowEnabled && templateName !== PORTFOLIO_TEMPLATES.CATHERINE

  return (
    <>
      <StylesLayoutAlignDropdown
        label='styles.footer.layoutAlign'
        name={STYLE_FEATURE_PROPERTIES.FOOTER_LAYOUT_ALIGN}
      />
      <StylesRadioGroup
        name={STYLE_FEATURE_PROPERTIES.FOOTER_LINK_STYLE}
        label='styles.footer.defaultFooterLinks'
        items={footerStyleItems}
      />
      <DefaultFieldsBlock
        fontFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_FONT_NAME}
        fontIdFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_FONT_ID}
        sizeFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_SIZE}
        colorFieldName={STYLE_FEATURE_PROPERTIES.FOOTER_COLOR}
        {...props}
      />
      <StylesToggle
        name={STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_YEAR}
        label='styles.footer.toggle.showYear'
        {...props}
      />
      <StylesToggle
        name={STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_DOMAIN_TITLE}
        label='styles.footer.toggle.showDomainTitle'
        {...props}
      />
      {showFooterOverSlideshowToggle && (
        <StylesToggle
          label='styles.footer.toggle.overSlideshow'
          {...props}
          field={{
            value: isFooterOverSlideshowEnabled,
            name: STYLE_FEATURE_PROPERTIES.FOOTER_OVER_SLIDESHOW,
          }}
          onClick={handleToggleFooterOverSlideshow}
        />
      )}
    </>
  )
}

export const AlbumFieldsBlock = ({ formatMessage, ...props }) => (
  <>
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.titles' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_COLOR}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.description' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_COLOR}
      {...props}
    />
    <SubHeader>{formatMessage({ id: 'styles.title.albumPage.note' })}</SubHeader>
    <DefaultFieldsBlock
      fontFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_NAME}
      fontIdFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_ID}
      sizeFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_SIZE}
      colorFieldName={STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_COLOR}
      {...props}
    />
  </>
)

export const ContactFieldsBlock = (props) => (
  <DefaultFieldsBlock
    fontFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_NAME}
    fontIdFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_ID}
    sizeFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_SIZE}
    colorFieldName={STYLE_FEATURE_PROPERTIES.CONTACTS_COLOR}
    {...props}
  />
)

export const TextFieldsBlock = (props) => (
  <DefaultFieldsBlock
    fontFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME}
    fontIdFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID}
    sizeFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE}
    colorFieldName={STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR}
    {...props}
  />
)

export const BackGroundFieldsBlock = () => (
  <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR} />
)

export const HoverFieldsBlock = () => (
  <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.HOVER_COLOR} />
)

export const BurgerFieldsBlock = () => (
  <StylesColorPicker name={STYLE_FEATURE_PROPERTIES.BURGER_COLOR} />
)

export const LoaderAnimationBlock = (props) => (
  <CustomLoaderPicker
    colorFieldName={STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_COLOR}
    variantFieldName={STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_VARIANT}
    customFieldName={STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM}
    {...props}
  />
)

export const SocialLinksBlock = (props) => (
  <SocialLinks
    iconTypeName={STYLE_FEATURE_PROPERTIES.FOOTER_ICON_TYPE}
    iconColorName={STYLE_FEATURE_PROPERTIES.FOOTER_SOCIAL_ICON_COLOR}
    socialLinksName={STYLE_FEATURE_PROPERTIES.SOCIAL_LINKS}
    {...props}
  />
)
