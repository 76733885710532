import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { intlShape } from 'react-intl'
import { Prompt } from 'react-router-dom'
import get from 'lodash/get'
import set from 'lodash/set'
import reduce from 'lodash/reduce'
import clone from 'lodash/clone'
import slice from 'lodash/slice'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import { Semantic } from 'packages/components'
import PageCreateModal from '@configurator/containers/PageCreateModalContainer'
import AlbumCreateModal from '@configurator/containers/AlbumCreateModalContainer'
import { ORDER_UPDATE_TIMEOUT, MAX_ERRORS_COUNT } from '@configurator/constants/pages'
import {
  PAGE_TYPES,
  ALBUM_TYPES,
  WEBSITE_BLOCK,
  MESSAGE_TYPE,
  UPDATE_APOLLO_TYPES,
  WEBSITE_PAGE_TYPES,
  URLS,
  WEBSITE_PAGE_BLOCK_TYPES,
  WEBSITE_NAVIGATION_VIEW_MODE,
} from 'packages/enum'
import { VIEW_MODE_FEATURES } from './const'
import { useItemsOptimisticRequests } from 'packages/helpers/useItemsOptimisticRequests'
import { push } from 'connected-react-router'
import Accordion from '@configurator/containers/AccordionContainer'
import PageLinkNode from '@configurator/components/navigation/pageLinkNode'
import CustomDragLayer from '@configurator/components/navigation/CustomDragLayer'
import AlbumNode from '@configurator/components/navigation/albumNode'
import PagePlaceholder from '@configurator/components/navigation/pageLinkNode/placeholder'
import Slideshow from '@configurator/containers/SlideshowContainer'
import { HomeIcon, PlusIcon, SlideshowIcon, SearchIcon } from 'packages/components/icons/basic'
import DropdownMenu from 'packages/components/dropdownMenu'
import { ChooseTemplate } from '@configurator/components/configPanel/ChooseTemplate'
import { TemplatesPanelPosition } from '@configurator/components/configPanel/nodes'
import { TabPropTypes } from 'packages/components/tabs'
import {
  Container,
  LoaderContainer,
  NavigationDataContainer,
  HeaderContainer,
  HeaderSvgContainer,
  DropdownContainer,
  SearchContainer,
  SearchButton,
  SearchInput,
  NotFoundTitle,
} from './nodes'
import { IconWrapper, Title } from './pageLinkNode/nodes'
import {
  close as closeSubscriptionModal,
  open as openSubscriptionModal,
} from 'packages/redux/modules/modalConfirmDelete/actions'
import { useSubscription } from '@configurator/providers/subscription'
import { filterNavigationPages } from 'packages/utils/filterNavigationPages'

const Navigation = (
  {
    viewMode,
    navigation,
    slideshowEnabled,
    tab: { setBackArrowState, clearBackArrowState },

    clickPage,
    deletePage,
    movePage,
    clickAlbum,
    deleteAlbum,

    onAlbumsOrderUpdate,
    loaderStart,
    loaderStop,
    openInfoModal,
    closeInfoModal,
    redirectTo,
    refetchPreviewMetaData,
  },
  { intl: { formatMessage } }
) => {
  const {
    pagesToShow,
    singlePageCreation,
    mainPagesCreatable,
    footerPagesCreatable,
    withTemplatesPanel,
    withSearch,
    withSlideshow,
    pagesDraggable,
    pagesDeletable,
    pagesCustomizable,
    albumsDraggable,
    albumsDeletable,
    albumsCustomizable,
    albumsCreatable,
    pageClickDisabled,
  } = VIEW_MODE_FEATURES[viewMode]

  const dispatch = useDispatch()
  const redirectToPage = useCallback(
    (url) => {
      refetchPreviewMetaData()
      dispatch(push(`${URLS.website_config}/page/${encodeURIComponent(url)}`))
    },
    [dispatch, refetchPreviewMetaData]
  )
  const redirectToAlbum = useCallback(
    (url) => dispatch(push(`${URLS.website_config}/blocks/${encodeURIComponent(url)}`)),
    [dispatch]
  )
  const redirectToConfig = useCallback(() => dispatch(push(URLS.website_config)), [dispatch])
  const [pageCreateModalData, setPageCreateModalData] = useState()
  const [albumCreateModalOpen, setAlbumCreateModalOpen] = useState(false)
  const [isSlideshowEditing, setIsSlideshowEditing] = useState(false)
  const [activePageId, setActivePageId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [mainNavigation, setMainNavigation] = useState(get(navigation, 'mainNavigation', []))
  const [footerNavigation, setFooterNavigation] = useState(get(navigation, 'footerNavigation', []))
  const [isLocationChanged, setIsLocationChanged] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  // Дадим пользователю пользоваться интерфейсом, только после маунта всех объектов DOM
  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  const redirectToSlideshow = useCallback(() => {
    redirectToConfig()
    setIsSlideshowEditing(true)
    setBackArrowState('tabs.tab.portfolio', () => {
      clearBackArrowState()
      setIsSlideshowEditing(false)
    })
  }, [setIsSlideshowEditing, setBackArrowState, clearBackArrowState, redirectToConfig])

  const {
    isRequestLoading,
    isOrderChanged,
    data: navigationData,
    requestErrors,
    setData: setNavigationData,
    setIsOrderChanged,
    setSendData,
    setCanSendRequest,
    onOrderUpdate,
    setNextLocation,
  } = useItemsOptimisticRequests({
    refetchItems: () => {
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_META,
        }),
        '*'
      )
    },
    optimisticRequest: (data) => onAlbumsOrderUpdate({ data }),
    loaderStart,
    loaderStop,
    openInfoModal,
    closeInfoModal,
    requestTimeout: ORDER_UPDATE_TIMEOUT,
    maxErrorsCount: MAX_ERRORS_COUNT,
    successMessage: formatMessage({ id: 'page.albums.info.success' }),
    loaderMessage: formatMessage({ id: 'page.albums.loading' }),
    errorMessage: formatMessage({ id: 'page.albums.info.error' }),
    optimisticRequestName: 'albumsOrderUpdate',
    getRequestArgs: (data) =>
      data.map(({ id, blocks }) => ({
        pageId: id,
        itemIds: getAlbumIds(blocks),
      })),
    redirectTo,
  })

  useEffect(() => {
    const mainPages = get(navigation, 'mainNavigation', [])
    const footerPages = get(navigation, 'footerNavigation', [])
    const filteredMainPages = filterNavigationPages(mainPages, searchValue, pagesToShow)
    const filteredFooterPages = filterNavigationPages(footerPages, searchValue, pagesToShow)
    setMainNavigation(filteredMainPages)
    setFooterNavigation(filteredFooterPages)
  }, [navigation, searchValue, pagesToShow])

  const handleSearchChange = (e) => setSearchValue(e.target.value)

  const handleDeletePage = ({ pageId }) => {
    setLoading(true)
    deletePage({ pageId }).then((res) => {
      setLoading(false)
      if (get(res, 'data.websitePageDelete.success')) {
        redirectToConfig()
      }
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_META,
        }),
        '*'
      )
    })
  }

  const handleDeleteAlbum = ({ albumId }) => {
    setLoading(true)
    deleteAlbum({ albumId }).then(() => {
      setLoading(false)
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_META,
        }),
        '*'
      )
    })
  }

  const getLeftPageId = ({ droppedId, type }) => {
    const data = type === PAGE_TYPES.MAIN ? mainNavigation : footerNavigation
    const droppedIndex = data.indexOf(data.find((item) => item.id === droppedId))
    return get(data, `[${droppedIndex - 1}].id`, null)
  }

  const getRightPageId = ({ itemId, type }) => {
    const data = type === PAGE_TYPES.MAIN ? mainNavigation : footerNavigation
    const itemIndex = data.findIndex((item) => item.id === itemId)
    return itemIndex !== -1 ? get(data, `[${itemIndex + 1}].id`, null) : null
  }

  const getLastPageId = ({ type }) => {
    const data = type === PAGE_TYPES.MAIN ? mainNavigation : footerNavigation
    return get(data, `[${data.length - 1}].id`)
  }

  const handleDropPage = ({ itemId, droppedId, type, changeNavigation, position }) => {
    return itemId !== droppedId
      ? movePage({
          rightId: position === 1 ? getRightPageId({ itemId: droppedId, type }) : droppedId,
          leftId: position === 1 ? droppedId : getLeftPageId({ droppedId, type }),
          itemId,
          changeNavigation,
        }).then(() => {
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_PAGE,
            }),
            '*'
          )
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )
        })
      : null
  }

  const getLeftAlbumId = ({ itemId, droppedId, type, pageId }) => {
    const data = type === ALBUM_TYPES.MAIN ? mainNavigation : footerNavigation
    let blocks = reduce(
      data.filter((data) => data.id === pageId),
      (arr, el) => arr.concat(el.blocks),
      []
    )
    if (!droppedId) {
      const albums = get(
        blocks.filter(
          (block) =>
            block.type === WEBSITE_BLOCK.ALBUM_LIST &&
            block.albums.findIndex((item) => item.id === itemId) !== -1
        ),
        '[0].albums',
        []
      )
      const itemIndex = albums.findIndex((item) => item.id === itemId)
      return get(albums, `[${itemIndex + 1}].id`, null)
    }
    const albums = get(
      blocks.filter(
        (block) =>
          block.type === WEBSITE_BLOCK.ALBUM_LIST &&
          block.albums.findIndex((item) => item.id === droppedId) !== -1
      ),
      '[0].albums',
      []
    )
    const droppedIndex = albums.findIndex((item) => item.id === droppedId)

    return get(albums, `[${droppedIndex - 1}].id`, null)
  }

  const handleUpdateApolloState = ({ itemId, leftId, item, newPageId, sourcePageId }) => {
    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.UPDATE_APOLLO_STATE,
        type: UPDATE_APOLLO_TYPES.page,
        leftId,
        itemId,
        item,
        newPageId,
        sourcePageId,
      }),
      '*'
    )
  }

  const getAlbumIds = (blocks = []) => {
    const albumInd = blocks.findIndex((block) => block.type === WEBSITE_BLOCK.ALBUM_LIST)
    const albums = get(blocks[albumInd], 'albums', [])
    return albums.map(({ id }) => id)
  }

  const handleDropAlbum = ({
    itemId,
    droppedId,
    type,
    newPageId,
    sourcePageId,
    sourceType,
    position,
  }) => {
    if (!droppedId || itemId !== droppedId /*&& droppedId !== getRightAlbumId({itemId, type})*/) {
      setIsOrderChanged(true)

      const leftId =
        position === 1
          ? droppedId
          : getLeftAlbumId({
              droppedId,
              type,
              itemId,
              pageId: newPageId || sourcePageId,
            })

      // получение перетаскиваемого альбома
      const sourceData = clone(sourceType === ALBUM_TYPES.MAIN ? mainNavigation : footerNavigation)
      const sourceBlockInd = sourceData.findIndex((el) => el.id === sourcePageId)
      const sourceBlocks = get(sourceData[sourceBlockInd], 'blocks', [])
      const sourceAlbumInd = sourceBlocks.findIndex(
        (block) => block.type === WEBSITE_BLOCK.ALBUM_LIST
      )
      const sourceAlbums = get(sourceBlocks[sourceAlbumInd], 'albums', [])
      const sourceItem = sourceAlbums.find((el) => el.id === itemId)

      // удаление перетаскиваемого альбома
      set(
        sourceData,
        `[${sourceBlockInd}].blocks[${sourceAlbumInd}].albums`,
        sourceAlbums.filter((el) => el.id !== itemId)
      )
      if (type === ALBUM_TYPES.MAIN) {
        setMainNavigation(sourceData)
      } else {
        setFooterNavigation(sourceData)
      }

      // вставка перетаскиваемого альбома в список альбомов
      const pageId = newPageId || sourcePageId
      const data = clone(type === ALBUM_TYPES.MAIN ? mainNavigation : footerNavigation)
      const blockInd = data.findIndex((el) => el.id === pageId)
      const blocks = get(data[blockInd], 'blocks', [])
      const albumInd = blocks.findIndex((block) => block.type === WEBSITE_BLOCK.ALBUM_LIST)
      const albums = get(blocks[albumInd], 'albums', [])

      const itemsToUpdate = albums.filter((el) => el.id !== itemId)
      const index = itemsToUpdate.findIndex((el) => el.id === leftId) + 1
      const leftPhotos = slice(itemsToUpdate, 0, index)
      const rightPhotos = slice(itemsToUpdate, index)
      const newAlbums = concat(leftPhotos, sourceItem, rightPhotos)
      set(data, `[${blockInd}].blocks[${albumInd}].albums`, newAlbums)
      const newData =
        type === ALBUM_TYPES.MAIN ? data.concat(footerNavigation) : mainNavigation.concat(data)
      if (type === ALBUM_TYPES.MAIN) {
        setMainNavigation(data)
      } else {
        setFooterNavigation(data)
      }
      setNavigationData(newData)
      setSendData(newData)
      handleUpdateApolloState({
        itemId,
        leftId,
        item: sourceItem,
        newPageId,
        sourcePageId,
      })
    }
    return null
  }

  const handleDropAlbumToPage = ({ itemId, newPageId, type, sourcePageId, sourceType }) => {
    const data = type === ALBUM_TYPES.MAIN ? mainNavigation : footerNavigation
    const blocks = get(
      data.filter((item) => item.id === newPageId),
      '[0].blocks',
      []
    )
    const albums = get(
      blocks.filter((block) => block.type === WEBSITE_BLOCK.ALBUM_LIST),
      '[0].albums',
      null
    )

    if (albums) {
      handleDropAlbum({
        itemId,
        droppedId: null,
        newPageId,
        type,
        sourcePageId,
        sourceType,
      })
    }
  }

  const renderBlock = ({ block, pageId, pageType, pageUrl }) => {
    if (block.type === WEBSITE_BLOCK.ALBUM_LIST) {
      return (
        <AlbumNode
          key={`${block.type}${pageId}${block.id}`}
          onCreateAlbum={() => {
            setAlbumCreateModalOpen(true)
            setActivePageId(pageId)
          }}
          pageId={pageId}
          albums={block.albums}
          albumType={pageType === PAGE_TYPES.MAIN ? ALBUM_TYPES.MAIN : ALBUM_TYPES.FOOTER}
          onClickAlbum={clickAlbum}
          onDeleteAlbum={handleDeleteAlbum}
          onDropAlbum={handleDropAlbum}
          redirectToPage={redirectToAlbum}
          draggable={albumsDraggable}
          deletable={albumsDeletable}
          customizable={albumsCustomizable}
          creatable={albumsCreatable}
          pageUrl={pageUrl}
        />
      )
    }
  }

  const onClickPage = pageClickDisabled ? () => null : clickPage

  const renderNavigationNode = ({ item, type }) => ({
    key: item.id,
    title: (
      <PageLinkNode
        url={item.url}
        title={item.title}
        id={item.id}
        startPage={item.startPage}
        password={item.password}
        pageType={type}
        onClickPage={onClickPage}
        onDeletePage={handleDeletePage}
        redirectToPage={redirectToPage}
        onDropPage={handleDropPage}
        onDropAlbum={handleDropAlbumToPage}
        isAlbumListExist={
          item.blocks.filter((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST).length > 0
        }
        showHeader={item.showHeader}
        visible={item.visible}
        type={get(item, 'blocks[0].type')}
        slideshowEnabled={slideshowEnabled}
        draggable={pagesDraggable}
        deletable={pagesDeletable}
        customizable={pagesCustomizable}
      />
    ),
    content:
      item.blocks && item.blocks.filter((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST).length > 0 ? (
        <>
          {item.blocks.map((block) =>
            renderBlock({
              block,
              pageId: item.id,
              pageType: type,
              pageUrl: item.url,
            })
          )}
        </>
      ) : null,
  })

  const handleChangeLocation = (location) => {
    if ((isOrderChanged || isRequestLoading) && !isLocationChanged) {
      setIsLocationChanged(true)
      setCanSendRequest(false)
      loaderStart({
        content: formatMessage({ id: 'album.items.loading' }),
      })
      setNextLocation(location)
      if (!isRequestLoading) {
        setSendData(navigationData)
        onOrderUpdate(navigationData)
      }
      return false
    }
    return true
  }

  const mainNavigationData = mainNavigation.map((item) =>
    renderNavigationNode({
      item,
      type: PAGE_TYPES.MAIN,
    })
  )

  const footerNavigationData = footerNavigation.map((item) =>
    renderNavigationNode({
      item,
      type: PAGE_TYPES.FOOTER,
    })
  )

  const { permissions } = useSubscription()

  const handleClickPageCreate = (main, type, blockType) => {
    if (
      (blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID && !permissions.INTEGRATIONS) ||
      (blockType === WEBSITE_PAGE_BLOCK_TYPES.SCRIPT && !permissions.HTML_PAGE) ||
      (type === WEBSITE_PAGE_TYPES.TEXT && !permissions.TEXT_PAGE) ||
      (type === WEBSITE_PAGE_TYPES.EXTERNAL_LINK && !permissions.EXTERNAL_LINK)
    ) {
      dispatch(
        openSubscriptionModal({
          headerMessageId: 'permission.modal.pro.max.header',
          subMessageId: 'permission.modal.pro.max.subMessage',
          yesMessageId: 'permission.modal.buttonMessage',
          onClickYes: () => {
            dispatch(push(URLS.website_billing))
            dispatch(closeSubscriptionModal())
          },
          hideCancelButton: true,
          styles: { width: '450px' },
        })
      )

      return
    }
    setPageCreateModalData({
      main,
      type,
      blockType,
    })
  }

  const createSinglePage = (main) => {
    handleClickPageCreate(main, singlePageCreation.type, singlePageCreation.blockType)
  }

  const getPageMenuItems = (main) => [
    {
      text: 'page.create.menu.title',
      header: true,
    },
    {
      text: 'page.create.menu.type.photo',
      onClick: () => handleClickPageCreate(main, WEBSITE_PAGE_TYPES.ALBUM_LIST),
    },
    {
      text: 'page.create.menu.type.contact',
      onClick: () => handleClickPageCreate(main, WEBSITE_PAGE_TYPES.CONTACTS),
    },
    {
      text: 'page.create.menu.type.text',
      onClick: () => handleClickPageCreate(main, WEBSITE_PAGE_TYPES.TEXT),
    },
    {
      text: 'page.create.menu.type.html',
      onClick: () =>
        handleClickPageCreate(main, WEBSITE_PAGE_TYPES.HTML, WEBSITE_PAGE_BLOCK_TYPES.SCRIPT),
    },
    {
      text: 'page.create.menu.type.ecwid',
      onClick: () =>
        handleClickPageCreate(main, WEBSITE_PAGE_TYPES.HTML, WEBSITE_PAGE_BLOCK_TYPES.ECWID),
    },
    {
      text: 'page.create.menu.type.link',
      onClick: () => handleClickPageCreate(main, WEBSITE_PAGE_TYPES.EXTERNAL_LINK),
    },
  ]

  const renderNavigationData = () => (
    <div>
      {withTemplatesPanel && (
        <TemplatesPanelPosition>
          <ChooseTemplate />
        </TemplatesPanelPosition>
      )}
      {withSearch && (
        <>
          <SearchContainer>
            <SearchButton>
              <SearchIcon color='darkGray' />
            </SearchButton>
            <SearchInput
              type='search'
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={formatMessage({ id: 'navigation.search.placeholder' })}
            />
          </SearchContainer>
          {isEmpty(mainNavigationData) &&
            isEmpty(footerNavigationData) &&
            !isEmpty(searchValue) && <NotFoundTitle>No result</NotFoundTitle>}
        </>
      )}
      <NavigationDataContainer>
        {(!isEmpty(mainNavigationData) || isEmpty(searchValue)) && (
          <div id='mainNavigationData'>
            <HeaderContainer main>
              <span>{formatMessage({ id: 'navigation.label.main' })}</span>
              {mainPagesCreatable && (
                <DropdownContainer>
                  {singlePageCreation ? (
                    <HeaderSvgContainer onClick={() => createSinglePage(true)}>
                      <PlusIcon />
                    </HeaderSvgContainer>
                  ) : (
                    <DropdownMenu
                      trigger={
                        <HeaderSvgContainer>
                          <PlusIcon />
                        </HeaderSvgContainer>
                      }
                      items={getPageMenuItems(true)}
                      size='m'
                    />
                  )}
                </DropdownContainer>
              )}
            </HeaderContainer>
            {withSlideshow && slideshowEnabled && (
              <Title isShowHeader isVisible>
                <IconWrapper paddingL='17' paddingR='17'>
                  <HomeIcon />
                </IconWrapper>
                <IconWrapper>
                  <SlideshowIcon />
                </IconWrapper>
                <span className='navTitle' onClick={redirectToSlideshow}>
                  {formatMessage({ id: 'tabs.tab.slideshow' })}
                </span>
              </Title>
            )}
            <Accordion data={mainNavigationData} multipleSelection />
            {!mainNavigationData.length && pagesDraggable && (
              <PagePlaceholder
                pageType={PAGE_TYPES.MAIN}
                onDropPage={handleDropPage}
                lastId={getLastPageId({ type: PAGE_TYPES.MAIN })}
              />
            )}
          </div>
        )}
        {(!isEmpty(footerNavigationData) || isEmpty(searchValue)) && (
          <div id='footerNavigationData'>
            <HeaderContainer>
              <span>{formatMessage({ id: 'navigation.label.footer' })}</span>
              {footerPagesCreatable && (
                <DropdownContainer>
                  {singlePageCreation ? (
                    <HeaderSvgContainer onClick={() => createSinglePage(false)}>
                      <PlusIcon />
                    </HeaderSvgContainer>
                  ) : (
                    <DropdownMenu
                      trigger={
                        <HeaderSvgContainer>
                          <PlusIcon />
                        </HeaderSvgContainer>
                      }
                      items={getPageMenuItems()}
                      size='m'
                    />
                  )}
                </DropdownContainer>
              )}
            </HeaderContainer>
            <Accordion data={footerNavigationData} multipleSelection />
            {!footerNavigationData.length && pagesDraggable && (
              <PagePlaceholder
                pageType={PAGE_TYPES.FOOTER}
                onDropPage={handleDropPage}
                lastId={getLastPageId({ type: PAGE_TYPES.FOOTER })}
              />
            )}
          </div>
        )}
      </NavigationDataContainer>
    </div>
  )

  return (
    <>
      <Prompt
        when={isOrderChanged || isRequestLoading || requestErrors.length < MAX_ERRORS_COUNT}
        message={handleChangeLocation}
      />
      <Container id='navigation'>
        {loading && (
          <LoaderContainer>
            <Semantic.Loader active size='large' />
          </LoaderContainer>
        )}
        <CustomDragLayer />
        {isSlideshowEditing ? <Slideshow /> : renderNavigationData()}
        {pageCreateModalData && (
          <PageCreateModal
            open
            onClose={() => setPageCreateModalData(undefined)}
            slideshowEnabled={slideshowEnabled}
            initialValues={{
              pagePosition: pageCreateModalData.main ? 'main' : 'footer',
              pageType: pageCreateModalData.type,
              blockType: pageCreateModalData.blockType,
            }}
          />
        )}
        {albumCreateModalOpen && (
          <AlbumCreateModal
            open
            pageId={activePageId}
            mainNavigation={mainNavigation}
            footerNavigation={footerNavigation}
            redirectToAlbum={redirectToAlbum}
            onClose={() => setAlbumCreateModalOpen(false)}
          />
        )}
      </Container>
    </>
  )
}

Navigation.propTypes = {
  viewMode: PropTypes.oneOf(Object.keys(WEBSITE_NAVIGATION_VIEW_MODE)),
  navigation: PropTypes.shape({
    mainNavigation: PropTypes.array.isRequired,
    footerNavigation: PropTypes.array.isRequired,
  }),
  tab: PropTypes.shape({
    setBackArrowState: PropTypes.func,
    clearBackArrowState: PropTypes.func,
  }),
  slideshowEnabled: PropTypes.bool,

  clickPage: PropTypes.func,
  deletePage: PropTypes.func,
  movePage: PropTypes.func,
  clickAlbum: PropTypes.func,
  deleteAlbum: PropTypes.func,

  onAlbumsOrderUpdate: PropTypes.func,
  loaderStart: PropTypes.func.isRequired,
  loaderStop: PropTypes.func.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  closeInfoModal: PropTypes.func.isRequired,

  redirectTo: PropTypes.func,
  refetchPreviewMetaData: PropTypes.func,
  ...TabPropTypes,
}

Navigation.defaultProps = {
  viewMode: WEBSITE_NAVIGATION_VIEW_MODE.MAIN,
  navigation: {
    mainNavigation: [],
    footerNavigation: [],
  },
  tab: {
    setBackArrowState: () => null,
    clearBackArrowState: () => null,
  },
  slideshowEnabled: false,

  deletePage: () => null,
  movePage: () => null,
  deleteAlbum: () => null,

  onAlbumDelete: () => null,
  onAlbumsOrderUpdate: () => null,
  redirectTo: () => null,
  refetchPreviewMetaData: () => null,
}

Navigation.contextTypes = {
  intl: intlShape,
}

export default Navigation
