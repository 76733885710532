import PropTypes from 'prop-types'
import React, { useEffect, useCallback, useMemo } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Form as FormikForm } from 'formik'
import isEqual from 'lodash/isEqual'
import { STYLE_TYPES, STYLE_FEATURE_PROPERTIES, FEATURE_PROPERTY_KEYS } from 'packages/enum'
import { usePrevious } from 'packages/helpers/usePrevious'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { Header, StylesButtons } from '@configurator/components/pages/pageStyles/components'
import {
  HeaderFieldsBlock,
  BurgerFieldsBlock,
  BackGroundFieldsBlock,
  ContactFieldsBlock,
  FooterFieldsBlock,
  HoverFieldsBlock,
  NavigationFieldsBlock,
  TextFieldsBlock,
  AlbumFieldsBlock,
  LoaderAnimationBlock,
  SocialLinksBlock,
} from './formBlocks'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
import { getPropertyMediaUrl } from 'packages/helpers/Helper'

const FormFields = React.memo(
  ({
    selectedStyle,
    formatMessage,
    formBlocksProps,
    templateName,
    scrollableHeader,
    toggleScollableHeader,
  }) => {
    switch (selectedStyle.type) {
      case STYLE_TYPES.header: {
        return <HeaderFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.navigation: {
        return (
          <NavigationFieldsBlock
            formatMessage={formatMessage}
            scrollableHeader={scrollableHeader}
            toggleScollableHeader={toggleScollableHeader}
            {...formBlocksProps}
          />
        )
      }
      case STYLE_TYPES.footer: {
        return <FooterFieldsBlock {...formBlocksProps} templateName={templateName} />
      }
      case STYLE_TYPES.album: {
        return <AlbumFieldsBlock formatMessage={formatMessage} {...formBlocksProps} />
      }
      case STYLE_TYPES.contacts: {
        return <ContactFieldsBlock {...formBlocksProps} />
      }
      case STYLE_TYPES.text: {
        return <TextFieldsBlock {...formBlocksProps} />
      }
      case STYLE_TYPES.burgerColor: {
        return <BurgerFieldsBlock />
      }
      case STYLE_TYPES.hoverColor: {
        return <HoverFieldsBlock />
      }
      case STYLE_TYPES.pageBackground: {
        return <BackGroundFieldsBlock />
      }
      case STYLE_TYPES.loaderAnimation: {
        return <LoaderAnimationBlock {...formBlocksProps} />
      }
      case STYLE_TYPES.socialLinks: {
        return <SocialLinksBlock {...formBlocksProps} />
      }
      default:
        return null
    }
  }
)

const StyleForm = ({
  initialValues,
  setValues,
  values,
  setFieldValue,
  selectedStyle,
  setSelectedStyle,
  updateFeatureState,
  resetFeaturesProp,
  openErrorModal,
  closeErrorModal,
  setBackArrowState,
  clearBackArrowState,
  slideshowEnabled,
  footerOverSlideshow,
  isFooterOverSlideshowEnabled,
  handleDiscard,
  handleToggleFooterOverSlideshow,
  templateName,
  intl: { formatMessage },
  scrollableHeader,
  toggleScollableHeader,
}) => {
  const prevValues = usePrevious(values)
  const { permissions } = useSubscription()

  const backArrowStateCb = useCallback(() => {
    if (!isEqual(values, initialValues)) {
      openErrorModal({
        onClickYes: () => {
          updateFeatureState({
            key: FEATURE_PROPERTY_KEYS.style,
            values: initialValues,
          })
          handleDiscard()
          setSelectedStyle(null)
          handleDiscard()
          closeErrorModal()
          clearBackArrowState()
        },
        hideHeader: true,
        subMessageId: 'discardChanges.subMessage',
        yesMessageId: 'discardChanges.yesMessage',
        styles: { width: '450px' },
      })
    } else {
      setSelectedStyle(null)
      clearBackArrowState()
    }
  }, [
    clearBackArrowState,
    closeErrorModal,
    initialValues,
    openErrorModal,
    setSelectedStyle,
    updateFeatureState,
    values,
    handleDiscard,
  ])

  useEffect(() => {
    setBackArrowState('tabs.tab.fontsAndColors', backArrowStateCb)
  }, [backArrowStateCb, setBackArrowState])

  useEffect(() => {
    if (prevValues && !isEqual(prevValues, values)) {
      updateFeatureState({ key: FEATURE_PROPERTY_KEYS.style, values })
    }
  }, [initialValues, prevValues, updateFeatureState, values])

  const getPropNamesToReset = useCallback(() => {
    switch (selectedStyle.type) {
      case STYLE_TYPES.header: {
        return [
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_SUBLOGO_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_TOP,
          STYLE_FEATURE_PROPERTIES.HEADER_LOGO_MARGIN_BOTTOM,
        ]
      }
      case STYLE_TYPES.navigation: {
        return [
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_SIZE,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_COLOR,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_ID,
          STYLE_FEATURE_PROPERTIES.HEADER_LINKS_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_SIZE,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_COLOR,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_ID,
          STYLE_FEATURE_PROPERTIES.PAGE_NAVIGATION_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.SCROLLABLE_HEADER,
        ]
      }
      case STYLE_TYPES.footer: {
        return [
          STYLE_FEATURE_PROPERTIES.FOOTER_LAYOUT_ALIGN,
          STYLE_FEATURE_PROPERTIES.FOOTER_SIZE,
          STYLE_FEATURE_PROPERTIES.FOOTER_COLOR,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_ID,
          STYLE_FEATURE_PROPERTIES.FOOTER_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.FOOTER_LINK_STYLE,
          STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_YEAR,
          STYLE_FEATURE_PROPERTIES.FOOTER_SHOW_DOMAIN_TITLE,
          STYLE_FEATURE_PROPERTIES.FOOTER_OVER_SLIDESHOW,
        ]
      }
      case STYLE_TYPES.album: {
        return [
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_TITLE_FONT_ID,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_DESCRIPTION_FONT_ID,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_COLOR,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_SIZE,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_SOURCE,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.ALBUM_PHOTO_NOTE_FONT_ID,
        ]
      }
      case STYLE_TYPES.contacts: {
        return [
          STYLE_FEATURE_PROPERTIES.CONTACTS_SIZE,
          STYLE_FEATURE_PROPERTIES.CONTACTS_COLOR,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_ID,
          STYLE_FEATURE_PROPERTIES.CONTACTS_FONT_SOURCE,
        ]
      }
      case STYLE_TYPES.text: {
        return [
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_SIZE,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_COLOR,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_NAME,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_ID,
          STYLE_FEATURE_PROPERTIES.TEXT_BLOCK_FONT_SOURCE,
        ]
      }
      case STYLE_TYPES.burgerColor: {
        return [STYLE_FEATURE_PROPERTIES.BURGER_COLOR]
      }
      case STYLE_TYPES.hoverColor: {
        return [STYLE_FEATURE_PROPERTIES.HOVER_COLOR]
      }
      case STYLE_TYPES.pageBackground: {
        return [STYLE_FEATURE_PROPERTIES.BACKGROUND_COLOR]
      }
      case STYLE_TYPES.loaderAnimation: {
        return [
          STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_COLOR,
          STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_VARIANT,
          STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM,
          getPropertyMediaUrl(STYLE_FEATURE_PROPERTIES.LOADER_ANIMATION_CUSTOM),
        ]
      }
      case STYLE_TYPES.socialLinks: {
        return [
          STYLE_FEATURE_PROPERTIES.FOOTER_ICON_TYPE,
          STYLE_FEATURE_PROPERTIES.FOOTER_SOCIAL_ICON_COLOR,
        ]
      }
      default:
        return []
    }
  }, [selectedStyle.type])

  const handleResetForm = useCallback(() => {
    const newValues = {}
    for (let key of Object.keys(values)) {
      newValues[key] = initialValues[key]
    }
    setValues({ ...newValues })
    resetFeaturesProp({ featurePropertyNames: getPropNamesToReset() })
  }, [getPropNamesToReset, initialValues, resetFeaturesProp, setValues, values])

  const formBlocksProps = useMemo(
    () => ({
      selectedStyle,
      values,
      initialValues,
      setFieldValue,
      openErrorModal,
      slideshowEnabled,
      footerOverSlideshow,
      isFooterOverSlideshowEnabled,
      handleToggleFooterOverSlideshow,
      templateName,
    }),
    [
      initialValues,
      openErrorModal,
      selectedStyle,
      setFieldValue,
      values,
      slideshowEnabled,
      footerOverSlideshow,
      isFooterOverSlideshowEnabled,
      handleToggleFooterOverSlideshow,
      templateName,
    ]
  )

  const isAllowed = () => {
    if (selectedStyle.type === STYLE_TYPES.socialLinks) {
      return permissions.SOCIAL_ICONS
    } else if (selectedStyle.type === STYLE_TYPES.loaderAnimation) {
      return permissions.CUSTOM_SPINNER
    } else {
      return permissions.FONTS_COLORS
    }
  }
  return (
    <FormikForm>
      <Header>{formatMessage({ id: selectedStyle.formTitle || selectedStyle.title })}</Header>
      <PermissionsOverlay isAllowed={isAllowed()}>
        <FormFields
          formatMessage={formatMessage}
          selectedStyle={selectedStyle}
          formBlocksProps={formBlocksProps}
          footerOverSlideshow={footerOverSlideshow}
          isFooterOverSlideshowEnabled={isFooterOverSlideshowEnabled}
          handleToggleFooterOverSlideshow={handleToggleFooterOverSlideshow}
          templateName={templateName}
          scrollableHeader={scrollableHeader}
          toggleScollableHeader={toggleScollableHeader}
        />
        <StylesButtons
          onResetClick={() => handleResetForm()}
          saveDisabled={isEqual(values, initialValues)}
        />
      </PermissionsOverlay>
    </FormikForm>
  )
}

StyleForm.propTypes = {
  slideshowEnabled: PropTypes.bool,
  selectedStyle: PropTypes.object.isRequired,
  updateFeatureState: PropTypes.func,
  resetFeaturesProp: PropTypes.func,
  openErrorModal: PropTypes.func,
  closeErrorModal: PropTypes.func,
  footerOverSlideshow: PropTypes.shape(),
  isFooterOverSlideshowEnabled: PropTypes.bool,
  handleDiscard: PropTypes.func,
  intl: intlShape.isRequired,
  handleToggleFooterOverSlideshow: PropTypes.func,
  templateName: PropTypes.string,
  scrollableHeader: PropTypes.shape(),
  toggleScollableHeader: PropTypes.func,
  ...formProptypes,
}

StyleForm.defaultProps = {
  updateFeatureState: () => null,
  resetFeaturesProp: () => null,
  openErrorModal: () => null,
  closeErrorModal: () => null,
  handleDiscard: () => {},
}

export default injectIntl(StyleForm)
