import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { metaSelector } from 'packages/selectors/Website'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { URLS } from 'packages/enum'
import { ShortLeftIcon } from 'packages/components/icons'
import { viewTypes } from 'packages/components/const'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import {
  DevicesBlock,
  LeftBlock,
  ReturnArrowContainer,
  RightBlock,
  UserBlock,
  StyledPopup,
  PopupContent,
  PopupTitle,
  PopupText,
} from './nodes'
import mainLogo from './img/logo-light.svg'
import { useSubscription } from '@configurator/providers/subscription'
import { UserDropdown } from './UserDropdown'
import { useIsMobile } from '../../helpers/useWindowSize'
import { IconButton } from './iconButton'
import {
  BarChart,
  BrowserIcon,
  DesktopIcon,
  DesktopLargeIcon,
  LaptopIcon,
  PDFDownload,
  PhoneIcon,
  TabletIcon,
} from './icons'

export function Header({
  className,
  viewType,
  onTypeClick,
  pageUrl,
  onPdfToolsClick,
  onStatisticsClick,
  backArrow,
  isAlbumExpanded,
}) {
  const { permissions } = useSubscription()
  const meta = useSelector(metaSelector)
  const previewHref = useMemo(
    () => `https://${meta.domain}.${__DOMAIN__.replace('www.', '')}${pageUrl}`,
    [meta.domain, pageUrl]
  )
  const isMobile = useIsMobile()
  const location = useLocation()

  const isIntegrationItemPage =
    location.pathname.includes(URLS.website_integrations) &&
    location.pathname !== URLS.website_integrations

  const onDeviceButtonClick = (type) => () => {
    if (type === undefined) {
      return
    }

    onTypeClick(type)
  }

  // hiddenSize - опция точки остановы для отображения кнопки для текущего размера приложения.
  const deviceButtons = [
    {
      type: viewTypes.desktopLarge,
      icon: <DesktopLargeIcon />,
      tooltipTitle: 'header.devices.desktopLarge.tooltip.title',
      tooltipText: 'header.devices.desktopLarge.tooltip.text',
      size: 1920,
    },
    {
      type: viewTypes.desktop,
      icon: <DesktopIcon />,
      tooltipTitle: 'header.devices.desktop.tooltip.title',
      tooltipText: 'header.devices.desktopLarge.tooltip.text',
      size: 1440,
    },
    {
      type: viewTypes.laptop,
      icon: <LaptopIcon />,
      tooltipTitle: 'header.devices.laptop.tooltip.title',
      tooltipText: 'header.devices.laptop.tooltip.text',
      size: 1024,
    },
    {
      type: viewTypes.tablet,
      icon: <TabletIcon />,
      tooltipTitle: 'header.devices.tablet.tooltip.title',
      tooltipText: 'header.devices.tablet.tooltip.text',
      hiddenSize: 'mobile',
      size: 768,
    },
    {
      type: viewTypes.mobile,
      icon: <PhoneIcon />,
      tooltipTitle: 'header.devices.phone.tooltip.title',
      tooltipText: 'header.devices.phone.tooltip.text',
      size: 375,
    },
    {
      href: previewHref,
      icon: <BrowserIcon />,
      tooltipTitle: 'header.devices.browser.tooltip.title',
      tooltipText: 'header.devices.browser.tooltip.text',
    },
  ]

  return (
    <div className={className}>
      <LeftBlock>
        <Link to={URLS.website_config}>
          <img id='vsble-logo' src={mainLogo} alt='' />
        </Link>
        {backArrow && (
          <Link to={isIntegrationItemPage ? URLS.website_integrations : URLS.website_config}>
            <ReturnArrowContainer>
              <ShortLeftIcon />
              <FormattedMessage
                id={
                  isIntegrationItemPage
                    ? 'header.arrowBackToIntegrations.text'
                    : 'header.arrowBack.text'
                }
              />
            </ReturnArrowContainer>
          </Link>
        )}
      </LeftBlock>
      <RightBlock>
        {isMobile && !backArrow ? (
          <DevicesBlock>
            <IconButton
              variant='selectable'
              isSelected={true}
              href={previewHref}
              as='a'
              target='_blank'
              rel='noreferrer'
            >
              <BrowserIcon />
            </IconButton>
          </DevicesBlock>
        ) : null}
        {onTypeClick && viewType && !isAlbumExpanded && (
          <DevicesBlock>
            {deviceButtons.map((device, index) => {
              if (isMobile && !device.href) {
                return <React.Fragment key={device.type} />
              }

              const linkProps = device.href && {
                as: 'a',
                href: device.href,
                target: '_blank',
                rel: 'noreferrer',
              }

              return (
                <StyledPopup
                  key={index}
                  trigger={
                    <IconButton
                      variant='selectable'
                      isSelected={viewType === device.type}
                      onClick={onDeviceButtonClick(device.type)}
                      {...linkProps}
                    >
                      {device.icon}
                    </IconButton>
                  }
                  content={
                    <PopupContent>
                      <PopupTitle>
                        <FormattedMessage id={device.tooltipTitle} values={{ px: device.size }} />
                      </PopupTitle>
                      <PopupText>
                        <FormattedMessage id={device.tooltipText} values={{ px: device.size }} />
                      </PopupText>
                    </PopupContent>
                  }
                  position='bottom center'
                />
              )
            })}
          </DevicesBlock>
        )}
        <UserBlock>
          {onPdfToolsClick && (
            <>
              <PermissionsOverlay isAllowed={permissions.PDF}>
                <IconButton onClick={onPdfToolsClick}>
                  <PDFDownload />
                </IconButton>
              </PermissionsOverlay>
              <PermissionsOverlay isAllowed={permissions.STATISTICS}>
                <IconButton onClick={onStatisticsClick}>
                  <BarChart />
                </IconButton>
              </PermissionsOverlay>
            </>
          )}
          <UserDropdown />
        </UserBlock>
      </RightBlock>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string.isRequired,
  viewType: PropTypes.oneOf(Object.values(viewTypes)),
  onTypeClick: PropTypes.func,
  onPdfToolsClick: PropTypes.func,
  onStatisticsClick: PropTypes.func,
  pageUrl: PropTypes.string,
  backArrow: PropTypes.bool,
  isAlbumExpanded: PropTypes.bool,
}

Header.defaultProps = {
  pageUrl: '',
  onTypeClick: undefined,
  onPdfToolsClick: undefined,
  onStatisticsClick: undefined,
  viewType: undefined,
  backArrow: false,
  isAlbumExpanded: false,
}
