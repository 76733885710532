import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import {
  FormInput,
  FormRadio,
  FormToggle,
  FormTextArea,
  FormInputPassword,
} from 'packages/components/inputs'
import { Button } from 'packages/components/buttons'
import { WEBSITE_PAGE_BLOCK_TYPES, URLS } from 'packages/enum'

import {
  StyledInput,
  Description,
  MobileSubmitContainer,
  SmallTitle,
  StDividedField,
} from '@configurator/components/modals/nodes'
import {
  StyledRadio,
  Password,
  StyledInputCode,
  LinkComponent,
} from '@configurator/components/modals/pageCreate/nodes'
import { StPagePosition, StPagePositionGroup } from './nodes'

import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { StyledSlug, StyledSlugPreview } from '../albumUpdate/nodes'

function MainSettingsTab({
  intl: { formatMessage },
  permissions,
  isLinkPage,
  slideshowEnabled,
  isHTMLPage,
  values,
  renderContactOptionsBlock,
  isSubmitting,
  isContactPage,
  url = '',
}) {
  return (
    <>
      <StyledInput>
        <FormInput name='title' type='text' label='newDesign.page.update.field.title' />
      </StyledInput>
      <StyledInput hide={isLinkPage}>
        <FormInput
          name='slug'
          type='text'
          label='newDesign.page.update.field.slug'
          data-intercom-target='PageSlug'
        />
        <StyledSlugPreview>
          {url}
          <StyledSlug>{values.slug}</StyledSlug>
        </StyledSlugPreview>
      </StyledInput>
      <StyledInput hide={!isLinkPage}>
        <FormInput
          validate={(value) => isLinkPage && !value && 'page.field.error.url'}
          name='url'
          type='text'
          label='newDesign.page.update.field.linkUrl'
        />
      </StyledInput>
      {values.blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID && (
        <LinkComponent target={'_blank'} href={`${URLS.website_integrations}/ecwid`}>
          {formatMessage({
            id: 'newDesign.page.create.ecwid.link',
          })}
        </LinkComponent>
      )}
      <StyledInputCode hide={!isHTMLPage}>
        <FormTextArea
          name='content'
          type='text'
          maxLength={50000}
          label={
            values.blockType === WEBSITE_PAGE_BLOCK_TYPES.ECWID
              ? formatMessage({
                  id: 'newDesign.page.create.ecwid.page.code',
                })
              : formatMessage({
                  id: 'newDesign.page.create.page.code',
                })
          }
        />
      </StyledInputCode>
      {renderContactOptionsBlock()}
      {!isContactPage && (
        <StDividedField>
          <StPagePosition>
            <SmallTitle>
              {formatMessage({
                id: 'newDesign.page.update.field.pagePosition',
              })}
            </SmallTitle>
            <StPagePositionGroup>
              <StyledRadio>
                <FormRadio name='mainNavigation' value />
                <span>
                  {formatMessage({
                    id: 'newDesign.page.update.field.mainNavigation',
                  })}
                </span>
              </StyledRadio>
              <StyledRadio>
                <FormRadio name='footerNavigation' value />
                <span>
                  {formatMessage({
                    id: 'newDesign.page.update.field.footerNavigation',
                  })}
                </span>
              </StyledRadio>
            </StPagePositionGroup>
          </StPagePosition>
        </StDividedField>
      )}
      <StDividedField hide={isLinkPage}>
        <div>
          <SmallTitle>
            {formatMessage({
              id: 'newDesign.page.update.field.enablePage.title',
            })}
          </SmallTitle>
          <Description>
            {formatMessage({
              id: 'newDesign.page.update.field.enablePage.desc',
            })}
          </Description>
        </div>
        <FormToggle name='visible' />
      </StDividedField>
      <StDividedField hide={slideshowEnabled}>
        <SmallTitle>
          {formatMessage({
            id: 'newDesign.page.update.field.landingPage',
          })}
        </SmallTitle>
        <FormToggle name='startPage' />
      </StDividedField>
      <StDividedField>
        <div>
          <SmallTitle>
            {formatMessage({
              id: 'newDesign.page.update.field.hidePage.title',
            })}
            {/* {isContactPage ? <ProFeatureBadge>PRO</ProFeatureBadge> : null} */}
          </SmallTitle>
          <Description>
            {formatMessage({
              id: 'newDesign.page.update.field.hidePage.desc',
            })}
          </Description>
        </div>
        <div>
          <PermissionsOverlay isAllowed={permissions.HIDE_PAGE_TITLE}>
            <FormToggle name='hideHeader' />
          </PermissionsOverlay>
        </div>
      </StDividedField>
      <Password hide={isLinkPage}>
        <div>
          <SmallTitle data-intercom-target='PagePassword'>
            {formatMessage({
              id: 'newDesign.page.update.field.password.title',
            })}
            {/* {isContactPage ? <ProFeatureBadge>PRO</ProFeatureBadge> : null} */}
          </SmallTitle>
          <Description>
            {formatMessage({
              id: 'newDesign.page.update.field.password.desc',
            })}
          </Description>
        </div>
        <PermissionsOverlay isAllowed={permissions.PASSWORD_PROTECTION}>
          <FormInputPassword name='password' type='text' />
        </PermissionsOverlay>
      </Password>
      <MobileSubmitContainer>
        <Button
          type='submit'
          content={formatMessage({
            id: 'newDesign.page.update.submit',
          })}
          fluid
          disabled={isSubmitting}
        />
      </MobileSubmitContainer>
      {/*<DeleteItem onClick={onDelete}>
        {formatMessage({
          id: 'newDesign.page.update.button.deletePage',
        })}
      </DeleteItem>*/}
    </>
  )
}

export default injectIntl(MainSettingsTab)

MainSettingsTab.propTypes = {
  permissions: PropTypes.shape(),
  isLinkPage: PropTypes.bool.isRequired,
  slideshowEnabled: PropTypes.bool.isRequired,
  isHTMLPage: PropTypes.bool.isRequired,
  values: PropTypes.shape(),
  renderContactOptionsBlock: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  isContactPage: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
}

MainSettingsTab.defaultProps = {
  permissions: {},
  values: {},
  renderContactOptionsBlock: () => {},
}
