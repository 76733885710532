import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { Field } from 'formik'
import { intlShape } from 'react-intl'
import { fieldProptypes } from './formikPropTypes'
import { Radio } from '../radio'

const SemanticRadioInput = ({
  field: { value: fieldValue, ...restField }, // { name, value, onChange, onBlur }
  form: { touched = {}, errors = {}, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid,
  label,
  value,
  ...props
}) => {
  const handleClick = () => {
    if (!props.disabled) {
      setFieldValue(restField.name, value)
    }
  }

  return (
    <Radio
      {...restField}
      {...props}
      onChange={handleClick}
      checked={fieldValue === value}
      error={errors[restField.name] && touched[restField.name]}
    />
  )
}

SemanticRadioInput.propTypes = {
  ...SemanticCheckbox.propTypes,
  ...fieldProptypes,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
}

SemanticRadioInput.defaultProps = {
  ...SemanticCheckbox.defaultProps,
}

SemanticRadioInput.contextTypes = {
  intl: intlShape,
}

export const FormRadio = ({ name, ...props }) => (
  <Field name={name} {...props} component={SemanticRadioInput} />
)

FormRadio.propTypes = {
  ...SemanticCheckbox.propTypes,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
}
