import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { Field } from 'formik'
import { intlShape } from 'react-intl'
import omit from 'lodash/omit'
import { fieldProptypes } from './formikPropTypes'
import { Toggle } from '../toggle'

const SemanticToggleInput = ({
  field: { value, ...restField }, // { name, value, onChange, onBlur }
  form: { touched = {}, errors = {}, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid,
  ...props
}) => {
  const handleClick = () => {
    if (!props.disabled) {
      setFieldValue(restField.name, !value)
    }
  }

  return (
    <Toggle
      {...restField}
      {...props}
      onChange={handleClick}
      checked={value}
      error={errors[restField.name] && touched[restField.name]}
    />
  )
}

SemanticToggleInput.propTypes = {
  ...fieldProptypes,
  ...omit(SemanticCheckbox.propTypes, 'value'),
}

SemanticToggleInput.defaultProps = {
  ...omit(SemanticCheckbox.defaultProps, 'value'),
}

SemanticToggleInput.contextTypes = {
  intl: intlShape,
}

export const FormToggle = ({ name, ...props }) => (
  <Field name={name} {...props} component={SemanticToggleInput} />
)

FormToggle.propTypes = {
  name: PropTypes.string.isRequired,
  ...omit(SemanticCheckbox.propTypes, 'value'),
}
