import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 30px 30px;
  justify-content: center;
  display: flex !important;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  > form {
    width: 100%;
  }
`

export const ButtonRow = styled.div`
  height: 45px;
  > button {
    height: 45px;
  }
`

export const FormRow = styled.div`
  margin-bottom: 20px;
  width: 100%;
  > div {
    width: 100%;
    .ui.input {
      width: 100%;
    }
  }

  .rc-slider-handle {
    border-radius: 7px;
  }
  hr {
    width: 100%;
    color: #e1e2e6;
    background: #e1e2e6;
    border: 1px solid;
  }
`

export const UploadLogo = styled.div`
  margin-bottom: 20px !important;
  width: 100%;
  .input {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #343840;
    border-radius: 2px;
    height: 45px;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
  }

  .image-preview {
    display: flex;
    align-items: center;
    > span {
      margin-left: 20px;
      margin-right: auto;
      color: #666a73;
    }
    img {
      width: 130px;
    }
    .input {
      height: 32px;
      color: #58c367;
      background: none;
      &:focus {
        outline-color: rgba(0, 0, 0, 0);
      }
    }

    > svg {
      margin-right: -6px;
      cursor: pointer;
    }
  }
`

export const LogoDescription = styled.span`
  font-size: 13px;
  text-align: left;
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.fonts.fontFamily};
  font-weight: bold;
  line-height: 140%;
  display: block;
  color: #343840;
  > span {
    margin-top: 5px;
    display: block;
    font-weight: normal;
    color: #666a73;
  }
`
export const Logo = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;

  > img {
    max-width: 130px;
  }
  .input {
    margin-left: auto;
    color: #58c367;
    background: none;
    cursor: pointer;
    &:focus {
      outline-color: rgba(0, 0, 0, 0);
    }
  }
  > svg {
    margin-right: -6px;
    cursor: pointer;
  }
`

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0;
  hr {
    width: -webkit-fill-available;
    color: #e1e2e6;
    background: #e1e2e6;
    border: 1px solid;
  }
  > span {
    width: max-content;
    min-width: max-content;
    text-align: center;
    margin-top: -0.5em;
    line-height: 1em;
    padding: 0 13px;
    font-size: 13px;
    color: #666a73;
  }
`

export const ImageComponent = styled.div`
  width: 130px;

  > div {
    max-width: 100px;
    max-height: 100px;
    background-color: transparent;
  }
`
