import PropTypes from 'prop-types'
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Semantic } from 'packages/components'
import { Modal } from 'packages/components/modal'
import { FormDropdown, FormTextArea } from 'packages/components/inputs'
import { formProptypes } from 'packages/utils/formikPropTypes'
import Message from 'packages/components/message/Message'
import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import AlignCheckbox from 'packages/components/alignCheckbox/alignCheckbox'
import { getDomain } from 'packages/helpers/Helper'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import {
  WEBSITE_BLOCK,
  BLOCK_PROPERTY_KEYS,
  WEBSITE_PAGE_TYPES,
  CONTACT_FORM_LOCALE,
  CONTACT_FORM_ALIGN,
  WEBSITE_PAGE_BLOCK_TYPES,
} from 'packages/enum'
import { Header, Content } from '@configurator/components/modals/nodes'
import {
  StyledDropdown,
  StyledTextareaContainer,
  UploadImageWrapper,
  AlignContainer,
} from './nodes'

import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'
import { useCallback } from 'react'
import MainSettingsTab from './mainSettingsTab'
import SeoSettingsTab from './seoSettingsTab'
import DisplayModeTab from './displayModeTab'
import { useUploadImage } from '../common/hooks/useUploadImage'
import ModalFooterButtons from '../common/modalFooterButtons/modalFooterButtons'
import ModalTabs from '../common/modalTabs/modalTabs'
import UploadImageBlock from '../common/uploadImageBlock/uploadImageBlock'

const languageDropdownOptions = [
  {
    key: CONTACT_FORM_LOCALE.de,
    value: CONTACT_FORM_LOCALE.de,
    text: 'Deutsch',
  },
  {
    key: CONTACT_FORM_LOCALE.en,
    value: CONTACT_FORM_LOCALE.en,
    text: 'English',
  },
  {
    key: CONTACT_FORM_LOCALE.fr,
    value: CONTACT_FORM_LOCALE.fr,
    text: 'French',
  },
  {
    key: CONTACT_FORM_LOCALE.ja,
    value: CONTACT_FORM_LOCALE.ja,
    text: 'Japanese',
  },
  {
    key: CONTACT_FORM_LOCALE.ru,
    value: CONTACT_FORM_LOCALE.ru,
    text: 'Russian',
  },
  {
    key: CONTACT_FORM_LOCALE.sp,
    value: CONTACT_FORM_LOCALE.sp,
    text: 'Spanish',
  },
]

const modalStyle = {
  width: '580px',
  height: 'initial',
}

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PageUpdate = (
  {
    open,
    onClose,
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    values,
    setFieldValue,
    deleteBlockProperty,
    refetchData,
    refetchPagePreview,
    meta,
    initialValues,
    openErrorModal,
    closeErrorModal,
    slideshowEnabled,
    premiumDomain,
    domain,
    title,
    seoPhotoUrl,
  },
  { intl: { formatMessage } }
) => {
  const [currentSeoPhotoUrl, setCurrentSeoPhotoUrl] = useState(seoPhotoUrl)
  useEffect(() => {
    setCurrentSeoPhotoUrl(seoPhotoUrl)
  }, [seoPhotoUrl])
  const prevValues = usePrevious(values)
  const [showModalDeleteImage, setShowModalDeleteImage] = useState(false)
  const { permissions } = useSubscription()
  const [activeTab, setActiveTab] = useState(0)

  const { handleDeletePreviewFile, handleDropFile, isFileReady, preview } = useUploadImage(
    setFieldValue,
    'file'
  )

  const {
    handleDeletePreviewFile: handleDeleteSeoPreviewFile,
    handleDropFile: handleDropSeoFile,
    isFileReady: isSeoFileReady,
    preview: seoPreview,
  } = useUploadImage(setFieldValue, 'seoImage')

  const deleteSeoPhotoUrl = () => {
    if (get(seoPreview, 'preview', '')) {
      handleDeleteSeoPreviewFile()
    } else {
      setFieldValue('deleteSeoPhotoUrl', true)
      setCurrentSeoPhotoUrl('')
    }
  }

  const undoDeleteSeoPhotoUrlOnDropFile = (files) => {
    handleDropSeoFile(files)
    setFieldValue('deleteSeoPhotoUrl', false)
  }

  const handleTabClick = useCallback(
    (idx) => {
      if (idx !== activeTab) {
        setActiveTab(idx)
      }
    },
    [activeTab]
  )

  const isHTMLPage = useMemo(() => {
    if (values?.blocks && values.blocks[0]?.type === WEBSITE_PAGE_TYPES.HTML) {
      return true
    }
    return false
  }, [values.blocks])

  const isLinkPage = useMemo(() => {
    if (values?.blocks && values.blocks[0]?.type === WEBSITE_PAGE_TYPES.EXTERNAL_LINK) {
      return true
    }
    return false
  }, [values.blocks])

  const error = Object.keys(errors).length || (status && !!status.error)

  const contactBlock = useMemo(() => {
    return (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.CONTACTS)
  }, [values.blocks])

  useEffect(() => {
    if (prevValues?.id !== values.id) {
      if (contactBlock) {
        const userPhotoText = (contactBlock.blockProperties || []).find(
          (el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoText
        )

        if (userPhotoText && !values.userPhotoText) {
          setFieldValue('userPhotoText', userPhotoText.value)
        }

        const align = (contactBlock.blockProperties || []).find(
          (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormAlign
        )

        if (align && align.value) {
          setFieldValue('align', align.value)
        }

        const language = (contactBlock.blockProperties || []).find(
          (el) => el.key === BLOCK_PROPERTY_KEYS.contactFormLang
        )

        if (language && language.value) {
          setFieldValue('language', language.value)
        }
      }

      setFieldValue('stacked', !values.expanded)
      setFieldValue('album', !values.zoom)
      setFieldValue('hideHeader', !values.showHeader)
    }

    if (prevValues?.mainNavigation !== values.mainNavigation) {
      setFieldValue('footerNavigation', !values.mainNavigation)
    }

    if (prevValues?.footerNavigation !== values.footerNavigation) {
      setFieldValue('mainNavigation', !values.footerNavigation)
    }

    if (prevValues?.stacked !== values.stacked) {
      setFieldValue('expanded', !values.stacked)

      if (values.stacked) {
        setFieldValue('zoom', false)
      }
    }
    if (prevValues?.expanded !== values.expanded) {
      setFieldValue('stacked', !values.expanded)

      if (!values.expanded) {
        setFieldValue('zoom', false)
      }
    }

    if (prevValues?.album !== values.album) {
      setFieldValue('zoom', !values.album)
    }

    if (prevValues?.zoom !== values.zoom) {
      setFieldValue('album', !values.zoom)
    }
  }, [contactBlock, prevValues, setFieldValue, values])

  const showExpandedOptions = useMemo(() => {
    return (
      (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST) &&
      get(meta, 'canExpand')
    )
  }, [values.blocks, meta])

  const onDropRejected = (files, accept) => {
    if (files.find((el) => el.size > MAX_IMAGE_SIZE)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: MAX_IMAGE_SIZE / 1024 / 1024 },
        hideCancelButton: true,
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
        hideCancelButton: true,
      })
    }
  }

  const renderContactOptionsBlock = () => {
    if (contactBlock) {
      const photoBlockProperty = (contactBlock.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.userPhotoMediaUrl
      )
      return (
        <>
          <PermissionsOverlay isAllowed={permissions.MULTILANG_CONTACT}>
            <StyledDropdown>
              <FormDropdown
                name='language'
                label='newDesign.page.update.field.language'
                options={languageDropdownOptions}
              />
            </StyledDropdown>
          </PermissionsOverlay>
          <StyledTextareaContainer>
            <AlignContainer>
              {formatMessage({
                id: 'page.update.field.textAlign',
              })}
              <AlignCheckbox
                name='left'
                alignment='left'
                active={values.align === CONTACT_FORM_ALIGN.LEFT}
              />
              <AlignCheckbox
                name='center'
                alignment='center'
                active={values.align === CONTACT_FORM_ALIGN.CENTER}
              />
              <AlignCheckbox
                name='right'
                alignment='right'
                active={values.align === CONTACT_FORM_ALIGN.RIGHT}
              />
            </AlignContainer>
            <FormTextArea label='newDesign.page.update.field.additionalText' name='userPhotoText' />
          </StyledTextareaContainer>

          {/* <StyledInput>
                        <FormInput
                            name="altEmail"
                            type="email"
                            label="newDesign.page.update.contacts.field.altEmail.label"
                        />
                        <Description size={12}>
                            {formatMessage({
                                id: "newDesign.page.update.contacts.field.altEmail.desc",
                            })}
                        </Description>
                    </StyledInput> */}

          <UploadImageWrapper>
            <UploadImageBlock
              uploadModalTitle={
                !!contactBlock ? 'newDesign.page.update.contacts.upload.modal.title' : ''
              }
              title={'newDesign.page.update.field.selectPhoto'}
              description={'newDesign.page.update.contacts.uploadImg.desc'}
              isSubmitting={isSubmitting}
              photoBlockProperty={photoBlockProperty}
              onDropFile={handleDropFile}
              onDropReject={onDropRejected}
              isFileReady={Boolean(isFileReady || photoBlockProperty)}
              fileName={get(preview, 'file.name')}
              onDeleteImageClick={() =>
                isFileReady ? handleDeletePreviewFile() : setShowModalDeleteImage(true)
              }
              fileSrc={
                photoBlockProperty
                  ? getDomain(photoBlockProperty.value)
                  : isFileReady
                  ? get(preview, 'preview', '')
                  : ''
              }
            />
          </UploadImageWrapper>
        </>
      )
    }
    return null
  }

  const getHeaderTitle = useMemo(() => {
    if (get(values, 'blocks[0].blockType') === WEBSITE_PAGE_BLOCK_TYPES.ECWID) {
      return 'newDesign.page.update.header.ecwid'
    }
    switch (get(values, 'blocks[0].type')) {
      case WEBSITE_PAGE_TYPES.EXTERNAL_LINK:
        return 'newDesign.page.update.header.url'
      case WEBSITE_PAGE_TYPES.CONTACTS:
        return 'newDesign.page.update.header.contact'
      case WEBSITE_PAGE_TYPES.TEXT:
        return 'newDesign.page.update.header.text'
      case WEBSITE_PAGE_TYPES.HTML:
        return 'newDesign.page.update.header.html'
      default:
        return 'newDesign.page.update.header.photo'
    }
  }, [values])

  const handleClose = () => {
    if (!isEqual(values, initialValues)) {
      openErrorModal({
        subMessageId: 'discardChanges.subMessage',
        yesMessageId: 'discardChanges.yesMessage',
        hideHeader: true,
        onClickYes: () => {
          onClose()
          closeErrorModal()
        },
        styles: { width: '450px' },
      })
    } else {
      onClose()
    }
  }

  const tabs = useMemo(() => {
    if (showExpandedOptions) {
      return [
        {
          text: 'newDesign.page.update.tabs.main',
          intercomAttribute: 'PageTabSettings',
        },
        {
          text: 'newDesign.page.update.tabs.seo',
          intercomAttribute: 'PageTabSEO',
        },
        {
          text: 'newDesign.page.update.tabs.displayMode',
          intercomAttribute: 'PageTabDisplayMode',
        },
      ]
    } else if (!!contactBlock) {
      return [
        {
          text: 'newDesign.page.update.tabs.main',
          intercomAttribute: 'PageTabSettings',
        },
        {
          text: 'newDesign.page.update.tabs.seo',
          intercomAttribute: 'PageTabSEO',
        },
      ]
    } else {
      return [
        {
          text: 'newDesign.page.update.tabs.main',
          intercomAttribute: 'PageTabSettings',
        },
        {
          text: 'newDesign.page.update.tabs.seo',
          intercomAttribute: 'PageTabSEO',
        },
      ]
    }
  }, [showExpandedOptions, contactBlock])

  const userDomain = `https://${premiumDomain || `${domain}.vsble.me`}`

  return (
    <Modal
      open={open}
      onClose={handleClose}
      styles={modalStyle}
      mobileWide
      variant='gray'
      showHeaderCloseIcon
      showCloseIcon={false}
    >
      <Content>
        <Semantic.Form
          noValidate
          error={error}
          loading={isSubmitting || loading}
          onSubmit={handleSubmit}
        >
          <Header>
            <span>{formatMessage({ id: getHeaderTitle })}</span>
          </Header>

          <ModalTabs tabs={tabs} activeIdx={activeTab} onTabClick={handleTabClick} />

          <Message error={error} messages={errors} content={status && status.error} />

          {activeTab === 0 ? (
            <MainSettingsTab
              permissions={permissions}
              isLinkPage={isLinkPage}
              slideshowEnabled={slideshowEnabled}
              isHTMLPage={isHTMLPage}
              isContactPage={!!contactBlock}
              values={values}
              isSubmitting={isSubmitting}
              renderContactOptionsBlock={renderContactOptionsBlock}
              url={`${userDomain}/`}
            />
          ) : activeTab === 1 ? (
            <SeoSettingsTab
              isLinkPage={isLinkPage}
              isHTMLPage={isHTMLPage}
              values={values}
              isSubmitting={isSubmitting}
              onDropSeoFile={undoDeleteSeoPhotoUrlOnDropFile}
              onRejectSeoFile={onDropRejected}
              isFileReady={isSeoFileReady}
              fileSrc={get(seoPreview, 'preview', '')}
              fileName={get(seoPreview, 'file.name', '')}
              onDeleteImagePreview={deleteSeoPhotoUrl}
              pageTitle={title}
              domain={userDomain}
              seoPhotoUrl={currentSeoPhotoUrl}
              seoDescription={meta.seoDescription}
            />
          ) : (
            activeTab === 2 &&
            showExpandedOptions && <DisplayModeTab setFieldValue={setFieldValue} values={values} />
          )}
          <ModalFooterButtons disabled={isSubmitting} onClose={onClose} />
        </Semantic.Form>
      </Content>
      <ModalConfirmDelete
        open={showModalDeleteImage}
        onClickYes={() => {
          const userPhotoProperty =
            (contactBlock.blockProperties || []).find(
              (el) => el.key === BLOCK_PROPERTY_KEYS.userPhoto
            ) || {}
          deleteBlockProperty({ propertyId: userPhotoProperty.id })
            .then(refetchData)
            .then(refetchPagePreview)
          setShowModalDeleteImage(false)
        }}
        onClickCancel={() => setShowModalDeleteImage(false)}
        onClose={() => setShowModalDeleteImage(false)}
      />
    </Modal>
  )
}

PageUpdate.propTypes = {
  url: PropTypes.string,
  pageId: PropTypes.number.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteBlockProperty: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  refetchPagePreview: PropTypes.func,
  openErrorModal: PropTypes.func,
  closeErrorModal: PropTypes.func,
  meta: PropTypes.shape({
    canExpand: PropTypes.bool,
  }),
  slideshowEnabled: PropTypes.bool,
  seoPhotoUrl: PropTypes.string,
  ...formProptypes,
}

PageUpdate.defaultProps = {
  open: false,
  refetchPagePreview: () => null,
  openErrorModal: () => null,
  closeErrorModal: () => null,
  meta: {
    canExpand: undefined,
  },
  slideshowEnabled: false,
}

PageUpdate.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default PageUpdate
