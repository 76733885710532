import React from 'react'
import * as Semantic from 'semantic-ui-react'
import { intlShape } from 'react-intl'
import { getTitle, intlDuck } from '../../utils'
import { StyledInputContainer, StyledLabel, StyledDropdown } from './nodes'

export const Dropdown = (
  { placeholder, label, className, ...props },
  { intl: { formatMessage } = intlDuck }
) => {
  return (
    <StyledInputContainer className={className}>
      {label ? <StyledLabel>{getTitle(label, formatMessage)}</StyledLabel> : null}
      <StyledDropdown
        selection
        maxLength={255}
        {...props}
        placeholder={getTitle(placeholder, formatMessage)}
      />
    </StyledInputContainer>
  )
}

Dropdown.propTypes = {
  ...Semantic.Input.propTypes,
}

Dropdown.defaultProps = {
  ...Semantic.Input.defaultProps,
}

Dropdown.contextTypes = {
  intl: intlShape,
}
