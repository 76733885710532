import styled from 'styled-components'

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  border-bottom: 1px solid #e1e2e6;
  margin-top: 35px;
  ${({ hidden }) =>
    hidden &&
    `
        opacity: 0;
        visibility: hidden;
    `}
`
export const Tab = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  padding-bottom: 12px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`
