import PropTypes from 'prop-types'
import React, { useEffect, useState, useMemo } from 'react'
import Dropzone from 'react-dropzone'
import get from 'lodash/get'
import { Semantic } from 'packages/components'
import Message from 'packages/components/message/Message'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { MAX_IMAGE_SIZE } from '@configurator/constants/upload'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import Image from 'packages/components/image/Image'
import { getDomain } from 'packages/helpers/Helper'
import { MESSAGE_TYPE } from 'packages/enum'
import { TrashButton, Button } from 'packages/components/buttons'
import { FormInput, FormSlider } from 'packages/components/inputs'

import {
  FormRow,
  UploadLogo,
  TextRow,
  Logo,
  Wrapper,
  ImageComponent,
  LogoDescription,
  ButtonRow,
} from './nodes'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { useSubscription } from '@configurator/providers/subscription'

const EditLogo = (
  {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    loading,
    setFieldValue,
    values,
    deleteLogo,
    refetchMeta,
    openErrorModal,
    logoRemoveBackground,
    loaderStart,
    loaderStop,
  },
  { intl: { formatMessage } }
) => {
  const { permissions } = useSubscription()

  const error = Object.keys(errors).length || (status && !!status.error)

  const [isLogoFileReady, setIsLogoFileReady] = useState(false)
  const [logoPreview, setLogoPreview] = useState()
  const [showModalDeleteLogo, setShowModalDeleteLogo] = useState(false)
  const [previewLogoData, setPreviewLogoData] = useState()

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result || '')
      }
    })
  }

  useEffect(() => {
    if (!logoPreview) {
      setPreviewLogoData(undefined)
    } else {
      getBase64(logoPreview.file).then((file) => {
        setPreviewLogoData(file)
      })
    }
  }, [logoPreview])

  useEffect(() => {
    window.frames['preview-frame']?.postMessage(
      JSON.stringify({
        name: MESSAGE_TYPE.SET_LOGO_PREVIEW,
        logoPreview: previewLogoData,
        logoSizePercent: values.logoSizePercent,
      }),
      '*'
    )
  }, [values.logoSizePercent, previewLogoData])

  const memoizedLogo = useMemo(() => get(values, 'logo'), [values])

  useEffect(() => {
    setFieldValue('logoSizePercent', get(memoizedLogo, 'logoSizePercent'))
  }, [memoizedLogo, setFieldValue])

  const handleDropFile = (files) => {
    if (files && files.length) {
      const file = files[0]
      const preview = {
        index: 0,
        file,
        name: file.name,
        preview: window.URL.createObjectURL(file),
      }

      setIsLogoFileReady(true)
      setLogoPreview(preview)
      setFieldValue('newLogo', file)
      setFieldValue('logoSizePercent', 1)
    }
  }

  const onDropRejected = (files, accept, maxSize) => {
    if (files.find((el) => el.size > maxSize)) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileSize.description',
        subMessageValues: { size: maxSize / 1024 / 1024 },
      })
    }
    const acceptArr = accept.split(', ')
    if (files.find((el) => !acceptArr.includes(el.type))) {
      return openErrorModal({
        headerMessageId: 'uploadFile.error.header',
        yesMessageId: 'uploadFile.error.ok',
        subMessageId: 'uploadFile.error.fileType.description',
        subMessageValues: { types: accept.replace(/image\//g, '') },
      })
    }
  }

  const renderUpload = () => {
    const isFileReady = isLogoFileReady
    const preview = logoPreview

    const accept = 'image/jpeg, image/gif, image/png'
    const maxSize = MAX_IMAGE_SIZE

    return (
      <UploadLogo>
        {isFileReady ? (
          <div className='image-preview'>
            <img src={preview.preview} alt='' />
            <span>{`/...${preview.file.name.slice(-10)}`}</span>
            <Dropzone
              accept={accept}
              onDrop={(files) => handleDropFile(files)}
              multiple={false}
              maxSize={maxSize}
              onDropRejected={(files) => onDropRejected(files, accept, maxSize)}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()} className='input'>
                  <input {...getInputProps()} />
                  {formatMessage({
                    id: 'editLogo.button.change',
                  })}
                </div>
              )}
            </Dropzone>
            <TrashButton
              onClick={() => {
                setLogoPreview(undefined)
                setIsLogoFileReady(false)
                setFieldValue('newLogo', undefined)
                setFieldValue('logoSizePercent', undefined)
              }}
            />
          </div>
        ) : (
          <Dropzone
            accept={accept}
            onDrop={(files) => handleDropFile(files)}
            multiple={false}
            maxSize={maxSize}
            onDropRejected={(files) => onDropRejected(files, accept, maxSize)}
          >
            {({ getInputProps, getRootProps }) => (
              <div {...getRootProps()} className='input'>
                <input {...getInputProps()} />
                {formatMessage({
                  id: 'editLogo.upload.customLogo',
                })}
              </div>
            )}
          </Dropzone>
        )}
      </UploadLogo>
    )
  }

  const renderImage = () => {
    const { hashPath, height = 1, width = 1 } = get(values, 'logo', {})

    const accept = 'image/jpeg, image/gif, image/png'
    const maxSize = MAX_IMAGE_SIZE

    return (
      <Logo>
        <ImageComponent>
          <Image
            aspectRatio={width / height}
            src={getDomain(hashPath)}
            imgStyle={{ objectFit: 'contain' }}
            alt=''
          />
        </ImageComponent>

        <Dropzone
          accept={accept}
          onDrop={(files) => handleDropFile(files)}
          multiple={false}
          maxSize={maxSize}
          onDropRejected={(files) => onDropRejected(files, accept, maxSize)}
        >
          {({ getInputProps, getRootProps }) => (
            <div {...getRootProps()} className='input'>
              <input {...getInputProps()} />
              {formatMessage({ id: 'editLogo.button.change' })}
            </div>
          )}
        </Dropzone>
        <TrashButton onClick={() => setShowModalDeleteLogo(true)} />
      </Logo>
    )
  }

  return (
    <Wrapper>
      <Semantic.Form
        noValidate
        error={error}
        loading={isSubmitting || loading}
        onSubmit={handleSubmit}
      >
        <Message error={error} errors={errors} content={status && status.error} />
        <FormRow>
          <FormInput
            name='siteName'
            label='siteName.update.field.siteName'
            placeholder='siteName.update.field.siteName'
            type='text'
            width='396px'
          />
        </FormRow>
        <FormRow>
          <FormInput
            name='siteDescription'
            label='siteName.update.field.siteDescription'
            placeholder='siteName.update.field.siteDescription'
            type='text'
            width='396px'
          />
        </FormRow>
        <TextRow>
          <hr />
          <span>{formatMessage({ id: 'editLogo.label.or' })}</span>
          <hr />
        </TextRow>
        <FormRow>
          <LogoDescription>
            {formatMessage({ id: 'editLogo.label.customLogo' })}
            <span>{formatMessage({ id: 'editLogo.text.customLogo' })}</span>
          </LogoDescription>
        </FormRow>

        <PermissionsOverlay isAllowed={permissions.UPLOADABLE_LOGO}>
          <FormRow>
            {!values.logo || values.newLogo ? renderUpload(true) : renderImage(true)}
          </FormRow>

          {(values.logo || values.newLogo) && (
            <FormRow>
              <FormSlider
                name='logoSizePercent'
                label={formatMessage({
                  id: 'slideshow.label.logoSize',
                })}
                min={0.2}
                max={1.8}
                step={0.05}
                bottomMessages={[
                  formatMessage({
                    id: 'styles.inputs.fontSize.value.small',
                  }),
                  formatMessage({
                    id: 'styles.inputs.fontSize.value.medium',
                  }),
                  formatMessage({
                    id: 'styles.inputs.fontSize.value.large',
                  }),
                ]}
              />
            </FormRow>
          )}
        </PermissionsOverlay>

        <ButtonRow>
          <Button
            type='submit'
            fluid
            disabled={isSubmitting}
            content={formatMessage({ id: 'editLogo.button.save' })}
          />
        </ButtonRow>
      </Semantic.Form>
      {!values.logo || values.newLogo ? null : (
        <Button
          fluid
          onClick={async () => {
            loaderStart({
              content: formatMessage({ id: 'editLogo.button.remove.background.done' }),
            })
            await logoRemoveBackground()
            setFieldValue('newLogo', undefined)
            setFieldValue('logoSizePercent', undefined)
            setLogoPreview(undefined)
            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.SET_LOGO_PREVIEW,
                logoPreview: undefined,
                logoSizePercent: undefined,
              }),
              '*'
            )
            await refetchMeta()
            window.frames['preview-frame']?.postMessage(
              JSON.stringify({
                name: MESSAGE_TYPE.UPDATE_META,
              }),
              '*'
            )
            loaderStop()
          }}
          content={formatMessage({ id: 'editLogo.button.remove.background' })}
        />
      )}

      <ModalConfirmDelete
        open={showModalDeleteLogo}
        onClickYes={() => {
          deleteLogo()
            .then(refetchMeta)
            .then(() =>
              window.frames['preview-frame']?.postMessage(
                JSON.stringify({
                  name: MESSAGE_TYPE.UPDATE_META,
                }),
                '*'
              )
            )
          setFieldValue('newLogo', undefined)
          setFieldValue('logoSizePercent', undefined)
          setLogoPreview(undefined)
          setIsLogoFileReady(false)
          setShowModalDeleteLogo(false)
        }}
        onClickCancel={() => setShowModalDeleteLogo(false)}
        onClose={() => setShowModalDeleteLogo(false)}
      />
    </Wrapper>
  )
}

EditLogo.propTypes = {
  initialValues: PropTypes.object.isRequired,
  deleteLogo: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  refetchMeta: PropTypes.func,
  openErrorModal: PropTypes.func,
  ...formProptypes,
}

EditLogo.defaultProps = {
  refetchMeta: () => null,
  openErrorModal: () => null,
}

EditLogo.contextTypes = {
  intl: PropTypes.object.isRequired,
}
export default EditLogo
