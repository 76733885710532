import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { formProptypes } from 'packages/utils/formikPropTypes'
import { UPLOAD_STEPS, FEATURE_PROPERTY_KEYS, OBJECT_FIT_KEYS, MESSAGE_TYPE } from 'packages/enum'
import WebsiteAlbum from '@configurator/containers/AlbumContainer'
import { FormRadio } from 'packages/components/inputs/formElements'
import { ImageIcon } from 'packages/components/icons/basic'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import { Semantic } from 'packages/components'
import UploadPhotosModal from '@configurator/containers/UploadPhotosContainer'
import {
  Description,
  StyledSlider,
  ImageFit,
  ImageFitHeader,
  ImageFitBox,
  ImageFitText,
  ImageFitName,
  ImageFitDesc,
  FullHeightIconContainer,
  ScaleToFixIconContainer,
  StyledButton,
  LoaderContainer,
} from './nodes'
import { useSubscription } from '@configurator/providers/subscription'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PhotosComponent = (
  {
    handleSubmit,
    isSubmitting,
    values,
    unLockModalProgress,
    uploads,
    items,
    setFieldValue,
    featureUpdate,
    refetchPreviewMetaData,
    refetchItems,
    setStatus,
    initialValues,
    setSubmitting,
    refetchData,
    removeAllPhotos,
  },
  { intl: { formatMessage } }
) => {
  const prevValues = usePrevious(values)
  const { permissions } = useSubscription()
  const [photos, setPhotos] = useState()
  const [uploadModalOpen, setUploadModalOpen] = useState()
  const [deleteModalOpen, setDeleteModalOpen] = useState()
  const [removeLoading, setRemoveLoading] = useState()
  // const error = Object.keys(errors).length || status && !!status.error;

  useEffect(() => () => unLockModalProgress(), [unLockModalProgress])

  useEffect(() => {
    if (photos) {
      setPhotos(
        photos.map((photo) => {
          let uploadStep = photo.uploadStep
          if (isSubmitting) {
            if (uploads.get(photo.name)) {
              uploadStep =
                uploads.get(photo.name) === 100 ? UPLOAD_STEPS.WAITING : UPLOAD_STEPS.UPLOADING
            } else if (photo.progress === 100) {
              // если в сторе нет фото с таким именем и progress 100%, то запрос на сервер завершен
              uploadStep = UPLOAD_STEPS.READY
            }
          }
          return {
            ...photo,
            progress: uploads.get(photo.name),
            loading: isSubmitting,
            uploadStep,
          }
        })
      )
    }
  }, [uploads, isSubmitting, photos])

  useEffect(() => {
    if (prevValues && prevValues.objectFit !== values.objectFit) {
      setFieldValue('fitContain', !values.fitCover)
      setFieldValue('fitCover', !values.fitContain)
    }
  }, [prevValues, setFieldValue, values])

  const updateFeatures = () => {
    const defaultError = 'slideshow.modal.error.default'
    setSubmitting(true)
    featureUpdate({
      featureId: values.id,
      properties: {
        [FEATURE_PROPERTY_KEYS.transitionSpeed]: values.transitionSpeed,
        [FEATURE_PROPERTY_KEYS.objectFit]: values.objectFit,
      },
    })
      .then((res) => {
        setSubmitting(false)
        let {
          data: { featureUpdate: { errors, success } = {} },
        } = res
        if (success) {
          refetchPreviewMetaData()
          refetchData()
          return setStatus({ error: undefined, success: true })
        }
        setStatus({ error: errors._error || defaultError })
      })
      .catch((err) => {
        setSubmitting(false)
        return setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      })
  }

  const refetchPreview = () => {
    refetchPreviewMetaData()
    return refetchItems({ albumId: +values.albumId })
  }

  // const handleDropFile = (files) => {
  //     if (files) {
  //         setPhotos(files.map((file, index) => ({
  //             index,
  //             file,
  //             name: file.name,
  //             preview: window.URL.createObjectURL(file),
  //         })));
  //         setUploadModalOpen(true);
  //     }
  // };

  // TODO если надо, сделать дропзон
  // const renderUpload = () => {
  //     return (
  //         <Dropzone
  //             accept="image/jpeg, image/gif"
  //             onDrop={handleDropFile}
  //             multiple
  //         >
  //             {({getInputProps, getRootProps}) => (
  //                 <div
  //                     {...getRootProps()}
  //                     className="input"
  //                 >
  //                     <input {...getInputProps()} />
  //                 </div>
  //             )}
  //         </Dropzone>
  //     );
  // };

  const onAddPhotosClick = useCallback(() => {
    setUploadModalOpen(true)
  }, [])

  const handleDelete = () => {
    setDeleteModalOpen(false)
    setRemoveLoading(true)
    const photoIds = items.map((el) => el.id)
    removeAllPhotos({ photoIds }).then(() => {
      refetchPreview()
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      setRemoveLoading(false)
    })
  }

  return (
    <>
      <form
        noValidate
        // error={error}
        // loading={isSubmitting || loading}
        onSubmit={handleSubmit}
      >
        <Description>{formatMessage({ id: 'slideshow.modal.upload.label' })}</Description>
        {/* TODO если надо, сделать слайдер как в дизайне (значение относительно центра) */}
        <StyledSlider
          name='transitionSpeed'
          min={400}
          max={10000}
          step={200}
          showValue
          label={formatMessage({ id: 'slideshow.modal.speed.label' })}
          bottomMessages={[
            formatMessage({ id: 'slideshow.modal.speed.slow' }),
            formatMessage({ id: 'slideshow.modal.speed.fast' }),
          ]}
        />
        <ImageFit>
          <ImageFitHeader>{formatMessage({ id: 'slideshow.fit.label' })}</ImageFitHeader>
          <ImageFitBox>
            <FormRadio name='objectFit' value={OBJECT_FIT_KEYS.contains} />
            <ImageFitText>
              <ImageFitName>{formatMessage({ id: 'slideshow.fit.fullHeight' })}</ImageFitName>
              <ImageFitDesc>{formatMessage({ id: 'slideshow.fit.fullHeight.desc' })}</ImageFitDesc>
            </ImageFitText>
            <FullHeightIconContainer>
              <div>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M12 22L16 18L13 18L13 5L11 5L11 18L8 18L12 22Z' fill='white' />
                  <path d='M12 2L16 6L13 6L13 19L11 19L11 6L8 6L12 2Z' fill='white' />
                </svg>
              </div>
            </FullHeightIconContainer>
          </ImageFitBox>
          <ImageFitBox>
            <FormRadio name='objectFit' value={OBJECT_FIT_KEYS.cover} />
            <ImageFitText>
              <ImageFitName>{formatMessage({ id: 'slideshow.fit.scaleToFix' })}</ImageFitName>
              <ImageFitDesc>{formatMessage({ id: 'slideshow.fit.scaleToFix.desc' })}</ImageFitDesc>
            </ImageFitText>
            <ScaleToFixIconContainer>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M14.5 4L16.54 6.04L14.1196 8.46235L15.5376 9.88042L17.96 7.46L20 9.5V4H14.5ZM15.5382 14.1182L14.1215 15.535L16.5442 17.9558L14.5 20H18.59H20V18.59V14.5L17.96 16.54L15.5382 14.1182ZM9.87854 15.535L8.46176 14.1182L6.04 16.54L4 14.5V18.59V20H5.41H9.5L7.45582 17.9558L9.87854 15.535ZM8.46235 9.88042L9.88042 8.46235L7.46 6.04L9.5 4H4V9.5L6.04 7.46L8.46235 9.88042Z'
                  fill='white'
                />
              </svg>
            </ScaleToFixIconContainer>
          </ImageFitBox>
          <StyledButton
            type='button'
            view='primary'
            onClick={updateFeatures}
            content={formatMessage({ id: 'slideshow.button.save' })}
            disabled={
              (values.objectFit === initialValues.objectFit &&
                values.transitionSpeed === initialValues.transitionSpeed) ||
              isSubmitting
            }
          />
        </ImageFit>
        {items && items.length ? (
          <WebsiteAlbum
            hideUpload
            hideCover
            hideText
            albumId={+values.albumId}
            photos={items}
            refetchPagePreview={refetchPreview}
            forceRefetchPagePreview
          />
        ) : null}
        <PermissionsOverlay isAllowed={permissions.UPLOAD_PHOTO}>
          <StyledButton
            view='secondaryBlack'
            icon={<ImageIcon />}
            onClick={onAddPhotosClick}
            type='button'
            data-intercom-target='SlideshowAddPhotosButton'
          >
            {formatMessage({ id: 'slideshow.button.addPhotos' })}
          </StyledButton>
        </PermissionsOverlay>
        {items && items.length ? (
          <StyledButton view='secondaryGray' type='button' onClick={() => setDeleteModalOpen(true)}>
            {formatMessage({ id: 'slideshow.button.removeAll' })}
          </StyledButton>
        ) : null}
      </form>
      {removeLoading && (
        <LoaderContainer>
          <Semantic.Loader active size='large' />
        </LoaderContainer>
      )}
      {deleteModalOpen && (
        <ModalConfirmDelete
          open
          subMessageId='slideshow.removeAll.text'
          onClickYes={handleDelete}
          onClickCancel={() => setDeleteModalOpen(false)}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
      {uploadModalOpen && (
        <UploadPhotosModal
          open
          albumId={+values.albumId}
          photos={items}
          onPhotosUpload={refetchPreview}
          onClose={() => setUploadModalOpen(false)}
        />
      )}
    </>
  )
}

PhotosComponent.propTypes = {
  loading: PropTypes.bool,
  uploads: PropTypes.object,
  initialValues: PropTypes.object,
  items: PropTypes.array,
  featureUpdate: PropTypes.func,
  refetchPreviewMetaData: PropTypes.func,
  refetchItems: PropTypes.func,
  refetchData: PropTypes.func,
  removeAllPhotos: PropTypes.func,
  ...formProptypes,
}

PhotosComponent.defaultProps = {
  loading: false,
  items: [],
  uploads: {},
  initialValues: {},
  featureUpdate: () => null,
  refetchPreviewMetaData: () => null,
  refetchItems: () => null,
  refetchData: () => null,
  removeAllPhotos: () => null,
}

PhotosComponent.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default PhotosComponent
