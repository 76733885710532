import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { FormRadio } from 'packages/components/inputs'
import { Container, Label, RadiosContainer, RadioContainer, RadioLabel } from './nodes'

const Component = ({ intl: { formatMessage }, label, name, items }) => {
  return (
    <Container>
      <Label>{formatMessage({ id: label })}</Label>
      <RadiosContainer>
        {items.map((item, index) => (
          <RadioContainer key={index}>
            <FormRadio name={name} value={item.value} />
            <RadioLabel>{formatMessage({ id: item.label })}</RadioLabel>
          </RadioContainer>
        ))}
      </RadiosContainer>
    </Container>
  )
}

Component.propTypes = {
  intl: intlShape.isRequired,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export const StylesRadioGroup = injectIntl(Component)
