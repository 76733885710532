import styled from 'styled-components'
import { Button } from 'packages/components/buttons/button'

export const Container = styled.div`
  position: relative;
  margin: 0 30px 0 20px;
`

export const StyleItem = styled.div`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #343840;

  cursor: pointer;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const ResetButton = styled(Button)`
  width: 100% !important;
  margin-top: 40px !important;
  display: block !important;
`

export const FeatureCodeButton = styled(Button)`
  width: 100% !important;
  margin-top: 10px !important;
  display: block !important;
`

export const Header = styled.h1`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  color: #343840;

  margin-bottom: 20px;
`

export const SubHeader = styled.h2`
  font-family: Sailec, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #343840;

  margin-bottom: 20px;
`

export const FormBlock = styled.div`
  margin-bottom: 30px;
  & > * {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`
