import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import { injectIntl, intlShape } from 'react-intl'
import { FormDropdown } from 'packages/components/inputs'
import { Container } from './nodes'
import { FOOTER_LAYOUT_ALIGN_TYPE } from 'packages/enum'

function Component({ intl: { formatMessage }, label, ...rest }) {
  const options = [
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.left' }),
      value: FOOTER_LAYOUT_ALIGN_TYPE.LEFT,
    },
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.right' }),
      value: FOOTER_LAYOUT_ALIGN_TYPE.RIGHT,
    },
    {
      text: formatMessage({ id: 'styles.footer.layoutAlign.center' }),
      value: FOOTER_LAYOUT_ALIGN_TYPE.CENTER,
    },
  ]

  return (
    <Container>
      <FormDropdown {...rest} label={formatMessage({ id: label })} options={options} fluid />
    </Container>
  )
}

Component.propTypes = {
  ...SemanticDropdown.propTypes,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export const StylesLayoutAlignDropdown = injectIntl(Component)
