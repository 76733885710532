import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router'
import { useSubscription } from '@configurator/providers/subscription'
import Loader from '@configurator/components/loader/Loader'
import PageStyleSubscription from '@configurator/components/pageStylesSubscription/PageStyleSubscription'
import PreviewContainer from 'packages/containers/PreviewContainer'
import { URLS } from 'packages/enum'
import PdfToolModal from '@configurator/containers/PdfToolModalContainer'
import { useIsMobile } from 'packages/helpers/useWindowSize'
import {
  Wrapper,
  HeaderStyled,
  SplitContent,
  LeftPanel,
  RightPanel,
  MobilePreviewContainer,
} from './nodes'
import { ReturnArrowContainer } from 'packages/components/header/nodes'
import { ShortLeftIcon } from 'packages/components/icons'
import { FormattedMessage } from 'react-intl'

const Layout = ({ leftPanel, history, location, expandedView }) => {
  const [view, setView] = useState()
  const [pdfToolModalOpen, setPdfToolModalOpen] = useState(false)
  const setPdfToolModalOpenTrue = useCallback(() => setPdfToolModalOpen(true), [])
  const setPdfToolModalOpenFalse = useCallback(() => setPdfToolModalOpen(false), [])

  const { loading } = useSubscription()

  const isMobile = useIsMobile()

  const url = useMemo(() => (location ? decodeURIComponent(location.pathname) : ''), [location])

  const isAlbumExpanded = useMemo(
    () => expandedView && url.indexOf(`${URLS.website_config}/blocks`) !== -1,
    [expandedView, url]
  )

  const pageUrl = useMemo(() => {
    return url
      .replace('//', '/')
      .replace(`${URLS.website_config}/page`, '')
      .replace(`${URLS.website_config}/blocks`, '')
      .replace(`${URLS.website_config}/style`, '')
      .replace(URLS.website_config, '')
  }, [url])

  useEffect(() => {
    if (isMobile === false && !view) {
      setView('desktop')
    }
  }, [isMobile, view])

  if (loading) {
    return <Loader />
  }

  const renderConfiguratorContent = () => (
    <SplitContent view={view}>
      <LeftPanel isAlbumExpanded={isAlbumExpanded}>
        {isMobile && (
          <MobilePreviewContainer>
            <ReturnArrowContainer onClick={() => setView()}>
              <ShortLeftIcon />
              <FormattedMessage id='header.arrowBack.text' />
            </ReturnArrowContainer>
            <PreviewContainer view={view} pageUrl={pageUrl} history={history} />
          </MobilePreviewContainer>
        )}
        {leftPanel}
      </LeftPanel>
      {!isMobile && (
        <RightPanel isAlbumExpanded={isAlbumExpanded}>
          <Route
            exact
            path={`${URLS.website_config}/styleSubscription`}
            children={({ match }) =>
              match ? (
                <PageStyleSubscription />
              ) : (
                <PreviewContainer view={view} pageUrl={pageUrl} history={history} />
              )
            }
          />
        </RightPanel>
      )}
    </SplitContent>
  )

  const showViewTypes = (location.pathname || '').indexOf(URLS.website_statistics) === -1

  return (
    <Wrapper>
      <HeaderStyled
        isAlbumExpanded={isAlbumExpanded}
        viewType={showViewTypes ? view : undefined}
        onTypeClick={showViewTypes ? setView : undefined}
        pageUrl={pageUrl}
        onPdfToolsClick={setPdfToolModalOpenTrue}
        onStatisticsClick={() => history.push(URLS.website_statistics)}
      />
      {renderConfiguratorContent()}
      {pdfToolModalOpen && <PdfToolModal open onClose={setPdfToolModalOpenFalse} />}
    </Wrapper>
  )
}

Layout.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  leftPanel: PropTypes.node,
  expandedView: PropTypes.bool,
}

Layout.defaultProps = {
  leftPanel: null,
  expandedView: false,
}

export default Layout
