import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Tabs } from 'packages/components/tabs'
import PageStylesContainer from '@configurator/containers/PageStylesContainer'
import EditLogoContainer from '@configurator/containers/EditLogoContainer'
import WebsiteBanner from '@configurator/containers/WebsiteBannerContainer'
import { StyledConfigPanel, StyledTab } from './nodes'

const panes = [
  {
    menuItem: 'tabs.tab.portfolio',
    render: ({ route, ...props }) => (
      <StyledTab>{renderRoutes(route.routes, { ...props })}</StyledTab>
    ),
  },
  {
    menuItem: 'tabs.tab.logo',
    render: (props) => (
      <StyledTab>
        <EditLogoContainer {...props} />
      </StyledTab>
    ),
  },
  {
    menuItem: 'tabs.tab.fontsAndColors',
    render: (props) => (
      <StyledTab>
        <PageStylesContainer {...props} />
      </StyledTab>
    ),
  },
]

export const ConfigPanel = ({ route }) => {
  return (
    <StyledConfigPanel>
      <Tabs panes={panes} route={route} />
      <WebsiteBanner />
    </StyledConfigPanel>
  )
}

ConfigPanel.propTypes = {
  route: PropTypes.object.isRequired,
}

ConfigPanel.defaultProps = {}
